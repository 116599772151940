import { compose, concat, indexBy, pathOr, pluck, prop, filter } from 'ramda'
import { useQuery, UseQueryOptions } from 'react-query'
import { IOrganizationFull, IOrganizationDepartment } from './options.type'
import React from 'react'

import useWebSystemRole from '@/hooks/useWebSystemRole'
import useComapnyCode from '@/hooks/useComapnyCode'

/**
 * 綠版 帶 companySeq 必填
 * 藍版 帶 companyCode 必填
 */
export const useOrganizationFullList = (
  query?: any,
  options?: UseQueryOptions<IOrganizationFull[]>
) => {
  const systemRole = useWebSystemRole()
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IOrganizationFull[]>(
    ['/{companyCode}/organization/full', { companyCode, ...query }],
    {
      enabled:
        systemRole === 'SystemAdmin' ? !!query?.companySeq : !!companyCode,
      select: data => pathOr([], ['data'], data),
    }
  )

  return { data, isLoading, refetch }
}

/**
 * 綠版 帶 companySeq 必填
 * 藍版 帶 companyCode 必填
 */
export const useDepartmentFullList = (
  query?: any,
  options?: UseQueryOptions<IOrganizationFull[]>
) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IOrganizationFull[]>(
    ['/{companyCode}/organization/full', { companyCode, ...query }],
    {
      select: data => pathOr([], ['data'], data),
      enabled:
        companyCode === 'systemAdmin' ? !!query?.companySeq : !!companyCode,
      ...options,
    }
  )

  const dataSource = React.useMemo(() => {
    let result: IOrganizationDepartment[] = []
    const pluckDepartments = pluck('departments', data || [])
    pluckDepartments.forEach(pluckDepartment => {
      result = concat(result, pluckDepartment)
    })
    return result
  }, [data])

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), dataSource)
  }, [dataSource])

  const showDepartmentWithOrganization = React.useCallback(
    (departmentId: number) => {
      const matchOrg = data?.filter(
        org => org.departments.filter(d => d.id === departmentId).length > 0
      )
      if (!matchOrg || matchOrg.length === 0) {
        return ''
      }
      return `${pathOr('', [0, 'shortName'], matchOrg)}/${compose<
        any,
        any,
        any,
        string
      >(
        pathOr('', [0, 'shortName']),
        filter((d: IOrganizationDepartment) => d.id === departmentId),
        pathOr([], [0, 'departments'])
      )(matchOrg)}`
    },
    [data]
  )

  return {
    dataSource,
    isLoading,
    byId,
    refetch,
    showDepartmentWithOrganization,
  }
}
