import useAuth from '@lib/auth'
import { useKeepTokenAlive } from '@lib/auth/useMineInfo'
import React from 'react'

const useKeepAlive = () => {
  const isNeedFetchKeepAliveRef = React.useRef(false)

  const { isAuthenticated } = useAuth()

  const { refetch } = useKeepTokenAlive()

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (isNeedFetchKeepAliveRef.current) {
        refetch()
        isNeedFetchKeepAliveRef.current = false
      }
    }, 1000 * 60)

    return () => {
      clearInterval(timer)
    }
  }, [refetch])

  const handleUserInteraction = React.useCallback(() => {
    if (isAuthenticated) {
      isNeedFetchKeepAliveRef.current = true
    }
  }, [isAuthenticated])

  React.useEffect(() => {
    document.addEventListener('mousemove', handleUserInteraction)
    document.addEventListener('mousemove', handleUserInteraction)
    return () => {
      document.removeEventListener('mousemove', handleUserInteraction)
      document.removeEventListener('mousemove', handleUserInteraction)
    }
  }, [handleUserInteraction])

  React.useEffect(() => {
    document.addEventListener('keydown', handleUserInteraction)
    return () => {
      document.removeEventListener('keydown', handleUserInteraction)
    }
  }, [handleUserInteraction])
}

export default useKeepAlive
