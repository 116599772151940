import { SVGProps } from 'react'

const Folder = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2.738 4h5.698c.471 0 .922.191 1.25.53l3.421 3.541c.328.34.779.53 1.25.53h12.905c.96 0 1.738.779 1.738 1.739v14.922c0 .96-.778 1.738-1.738 1.738H2.738C1.778 27 1 26.222 1 25.262V5.738C1 4.778 1.778 4 2.738 4z"
      fill="#44596C"
      fillRule="evenodd"
    />
  </svg>
)

export default Folder
