import React from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'

const StyledModal = styled(Modal)`
  animation-duration: 0s !important;
  transition: none !important;
`

export default class ModalComponent extends React.Component<ModalProps, any> {
  static confirm = Modal.confirm
  static error = Modal.error
  static success = Modal.success
  static info = Modal.info

  render() {
    return <StyledModal {...this.props}>{this.props.children}</StyledModal>
  }
}
