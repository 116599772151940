import { SVGProps } from 'react'
import cx from 'classnames'
import theme from '@assets/theme'

const EditSVG = ({
  className,
  color = theme.icon.primary,
  ...props
}: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    className={cx('svg-icon icon-edit', className)}
    {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill={color} fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M14 3.414l-10 10V16h2.586l10-10L14 3.414zM21 21c.552 0 1 .448 1 1s-.448 1-1 1H3c-.552 0-1-.448-1-1s.448-1 1-1zM14.707 1.293l4 4c.39.39.39 1.024 0 1.414l-11 11C7.52 17.895 7.265 18 7 18H3c-.552 0-1-.448-1-1v-4c0-.265.105-.52.293-.707l11-11c.39-.39 1.024-.39 1.414 0z"
                    transform="translate(-568 -329) translate(216 180) translate(0 24) translate(0 37) translate(0 76) translate(352 12)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default EditSVG
