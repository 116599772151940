import { SVGProps } from 'react'
import cx from 'classnames'

const FailSVG = ({ className, ...props }: SVGProps<any>) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    width="20" 
    height="20" 
    viewBox="0 0 20 20"
    className={cx('icon-fail', className)}
    {...props}>
    <defs>
      <path 
        id="a" 
        d="M9.394 1.2l-9.41 16.298a.7.7 0 0 0 .607 1.05H19.41a.7.7 0 0 0 .606-1.05L10.606 1.2a.7.7 0 0 0-1.212 0z"/>
    </defs>
    <g 
      fill="none" 
      fillRule="evenodd">
        <path d="M0 0h20v20H0z"/>
        <mask id="b" fill="#fff">
          <use xlinkHref="#a"/>
        </mask>
        <use fill="#FFC247" xlinkHref="#a"/>
        <g fill="#F34C39" mask="url(#b)">
          <path d="M0 0h20v20H0z"/>
        </g>
        <g mask="url(#b)">
          <path d="M0 0h20v20H0z"/>
          <path fill="#FFF" fillRule="nonzero" stroke="#FFF" strokeWidth=".7" d="M10 13.102c-.432 0-.781-.218-.781-.487V6.589c0-.27.35-.487.781-.487.432 0 .781.217.781.487v6.026c0 .269-.35.487-.781.487zm-.879 3.148a.879.879 0 1 0 1.758 0 .879.879 0 0 0-1.758 0z"/>
        </g>
    </g>
  </svg>
)

export default FailSVG
