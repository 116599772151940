import styled from 'styled-components'
import { mediaQuerys } from '@lib/constants/responsive'

type Props = {
  children?: any
  style?: React.CSSProperties
  className?: string
}

export function Layout({ children, style, className, ...props }: Props) {
  return (
    <LayoutWrapper>
      <Content className={className} style={style} {...props}>
        {children}
      </Content>
    </LayoutWrapper>
  )
}

const LayoutWrapper = styled.div`
  padding: 0 20px 16px 24px;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  /* padding: 24px; */
  flex: 1;
  min-height: calc(100vh - 112px);
  ${mediaQuerys.mobile} {
    padding: 12px !important;
  }
`
export default Layout
