import { SVGProps } from 'react'

const Group = ({ color = '#fff', ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H24V24H0z" />
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.875">
        <path
          d="M14.692 10.617v5.677c0 2.047-1.66 3.706-3.706 3.706h-7.28C1.659 20 0 18.34 0 16.294v-7.28c0-2.047 1.66-3.705 3.706-3.705h5.717"
          transform="translate(2 2)"
        />
        <path
          d="M9.383 5.308c0 2.932 2.377 5.309 5.308 5.309 2.932 0 5.31-2.377 5.31-5.309C20 2.377 17.622 0 14.69 0 11.76 0 9.383 2.377 9.383 5.308z"
          transform="translate(2 2)"
        />
      </g>
    </g>
  </svg>
)

export default Group
