import { pathOr } from 'ramda'
import history from '@/routes/history'
import { useAuth, UserPermission, ActionTypes } from '@/auth'
import useComapnyCode from '@/hooks/useComapnyCode'

/**
 * authurl：用於內頁權限的判斷與外頁路徑(authByUrl) 不同時
 * @param actionType
 * @param authurl 源路徑
 * @returns
 */

export default function useRouteAuth() {
  const { user } = useAuth()
  const companyCode = useComapnyCode()

  const pathname = history.location.pathname
  const authUrl = companyCode
    ? pathname.replace(companyCode, ':companyCode')
    : pathname

  const routeAuth = pathOr({}, ['authByUrl', authUrl], user) as UserPermission

  /**
   * 依據指定的 url 判斷是否在 authByUrl 被允許存取,
   * 不指定時依據 頁面的 url 判斷條件
   *
   * */
  const checkIsAuthenticated = (act: ActionTypes, url?: string) => {
    if (!url) {
      return routeAuth[act]
    }

    const _url = companyCode ? url.replace(companyCode, ':companyCode') : url

    const authByUrl = pathOr({}, ['authByUrl', _url], user) as UserPermission

    return authByUrl[act]
  }

  return [routeAuth, checkIsAuthenticated] as [
    UserPermission,
    (act: string, authurl?: string) => boolean
  ]
}
