import { Modal } from 'antd-mobile'
import { request } from '@/services'
import localStorageUtil from './localstorage'

const alert = Modal.alert

/**
 * @params funcName: app 中匹配的 function 名稱
 * @params param: 呼叫 app 裡的 function 所使用參數
 */
export const appConnect = (funcName: string, param?: any) => {
  if (param) {
    console.info(funcName, JSON.stringify(param))
  } else {
    console.log(funcName)
  }

  try {
    if (navigator.userAgent.match(/Android/i)) {
      return param
        ? window.Android[funcName](JSON.stringify(param))
        : window.Android[funcName]()
    }

    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return param
        ? window.webkit.messageHandlers[funcName].postMessage(
            JSON.stringify(param)
          )
        : window.webkit.messageHandlers[funcName].postMessage()
    }

    throw new Error('確定')
  } catch (error) {
    console.log(error)

    if (funcName !== 'getFCMToken') {
      alert('發生錯誤', '此功能只能在 APP 使用', [{ text: '確定' }])
    }
  }
}

export const useQrcodeScannerApp = () => {
  const handleOpenQRCodeScanner = (callback: (code: string) => any) => {
    appConnect('launchQRCode')

    window.onQRCodeResult = (code: string) => callback(code)
  }

  return handleOpenQRCodeScanner
}
//App用來收後端推播訊息
export const loadFCMToken = async (callback?: (token: string) => any) => {
  const token: string = appConnect('getFCMToken')

  console.log(token)

  await request('/user/fcmToken', {
    method: 'PUT',
    body: { fcmToken: token },
  })

  localStorageUtil.set('fcmtoken', token)

  if (callback) {
    return callback(token)
  }
}

export default appConnect
