const Material = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#878787" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M11.532 1.24l6.181 3.06C18.501 4.687 19 5.483 19 6.354v7.29c0 .871-.499 1.667-1.285 2.054l-6.181 3.06c-.653.323-1.422.323-2.075 0l-6.184-3.061c-.787-.393-1.281-1.193-1.275-2.06V6.353C2 5.483 2.5 4.687 3.285 4.3l6.183-3.06c.65-.32 1.414-.32 2.064 0zM3.545 6.643v6.999c-.002.29.163.557.423.687l5.759 2.851V9.703l-6.182-3.06zm13.909 0l-6.182 3.06v7.473l5.754-2.848c.262-.129.428-.394.429-.684l-.001-7.001zm-7.296-4.034L4.504 5.407 10.5 8.375l5.995-2.968-5.652-2.797c-.216-.107-.47-.107-.685-.001z"
                  transform="translate(-10 -502) translate(0 120) translate(0 368) translate(10 14)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Material
