import { SVGProps } from 'react'

const Static = ({ className, ...props }: SVGProps<any>) => (
  <svg
    className={`style-icon-static ${className}`}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_334_4496)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9785 6.66646C19.1031 7.21326 19.166 7.77231 19.166 8.33313H11.666V0.83313C13.3655 0.833123 15.0146 1.4103 16.3431 2.4701C17.6716 3.5299 18.6009 5.00949 18.9785 6.66646ZM17.256 6.66646C16.9793 5.73814 16.4757 4.89336 15.7908 4.20839C15.1058 3.52342 14.261 3.01985 13.3327 2.74313V6.66646H17.256Z"
        fill="#878787"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.832031 11.6664C0.832027 10.5399 1.0858 9.42781 1.57451 8.41279C2.06321 7.39778 2.77429 6.50589 3.65495 5.80337C4.5356 5.10085 5.5632 4.60574 6.66144 4.35481C7.75967 4.10388 8.90033 4.10357 9.9987 4.35391V9.99974H15.6445C15.8791 11.029 15.8938 12.0962 15.6875 13.1315C15.4813 14.1668 15.0588 15.147 14.4477 16.0078C13.8366 16.8686 13.0507 17.5907 12.1413 18.1269C11.232 18.663 10.2197 19.0012 9.17062 19.1193C8.12158 19.2373 7.05944 19.1325 6.05366 18.8118C5.04789 18.4911 4.12115 17.9617 3.33405 17.2583C2.54695 16.5548 1.91723 15.693 1.48608 14.7294C1.05492 13.7658 0.83205 12.7221 0.832031 11.6664ZM14.002 11.6664H8.33203V5.99641C7.21061 5.99641 6.11438 6.32895 5.18195 6.95198C4.24952 7.57501 3.52278 8.46054 3.09363 9.4966C2.66449 10.5327 2.5522 11.6727 2.77098 12.7726C2.98976 13.8724 3.52977 14.8827 4.32274 15.6757C5.1157 16.4687 6.126 17.0087 7.22587 17.2275C8.32574 17.4462 9.46579 17.334 10.5018 16.9048C11.5379 16.4757 12.4234 15.7489 13.0465 14.8165C13.6695 13.8841 14.002 12.7878 14.002 11.6664Z"
        fill="#878787"
      />
    </g>
    <defs>
      <clipPath id="clip0_334_4496">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Static
