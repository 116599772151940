const Maintain = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.18435 5.65582V16.4892M9.16602 16.25C8.98102 16.25 8.60185 16.0483 7.84518 15.645C7.07602 15.235 6.01352 14.8267 4.65185 14.57C3.12352 14.2833 2.36018 14.14 2.01268 13.7158C1.66602 13.2908 1.66602 12.6125 1.66602 11.2533V5.91416C1.66602 4.42832 1.66602 3.68499 2.20685 3.22749C2.74685 2.76999 3.38185 2.88916 4.65102 3.12749C7.15768 3.59916 8.65102 4.58499 9.16602 5.15082C9.68102 4.58499 11.1743 3.59916 13.681 3.12832C14.9502 2.88916 15.5852 2.76999 16.1252 3.22749C16.666 3.68499 16.666 4.42749 16.666 5.91416V8.33332M17.386 10.7833L17.9652 11.36C18.0815 11.4758 18.1738 11.6135 18.2367 11.7651C18.2997 11.9166 18.3321 12.0792 18.3321 12.2433C18.3321 12.4075 18.2997 12.57 18.2367 12.7216C18.1738 12.8732 18.0815 13.0108 17.9652 13.1267L14.9302 16.2067C14.6915 16.4451 14.3861 16.6055 14.0543 16.6667L12.1735 17.0733C12.1053 17.0886 12.0343 17.0864 11.9672 17.0671C11.9 17.0477 11.8388 17.0118 11.7892 16.9626C11.7395 16.9134 11.7031 16.8524 11.6832 16.7854C11.6632 16.7184 11.6605 16.6475 11.6752 16.5792L12.0752 14.7167C12.1377 14.3858 12.2985 14.0817 12.5377 13.8442L15.611 10.7825C15.8468 10.5479 16.1659 10.4162 16.4985 10.4162C16.8311 10.4162 17.1502 10.5487 17.386 10.7833Z"
        stroke="#878787"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Maintain
