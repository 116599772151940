import React from 'react'
import { Skeleton, Card } from 'antd'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  &.ant-card {
    background: transparent;
    border: transparent;

    .ant-card-body {
      padding: 6px 12px;
    }
  }
`

const SkeletonLoad = ({ length = 3 }: { length?: number }) => (
  <>
    {Array.from({ length }).map((_, idx) => (
      <StyledCard key={`skeleton-loading-${idx}`} title="" loading>
        <Skeleton loading avatar active />
      </StyledCard>
    ))}
  </>
)

export default SkeletonLoad
