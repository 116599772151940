/**
 * 百分比: 四捨五入到小數點第四位
 * 超過小數點後5位有值(非為0)一律用科學符號標示
 */
import envStore from '@lib/env'
import {
  BigNumber,
  isNil,
  numberFractionDigitsFormat,
  toThousandSeparator,
} from '@lib/utils/webHelper'
import { not } from 'ramda'
const defaultDecimalNumber = envStore.decimalNumber

/**
 *
 * @returns string "" | 千分位分號格式 | 科學符號顯示
 */
export const expoNumber = (v: any) => {
  if (isNil(v)) {
    return ''
  }

  let temp
  const bignum = BigNumber(v)
  const isLessThan = bignum.lt(1)
  const isGreaterThan = bignum.gt(-1)
  const decimalPart = String(Number(v)).split('.')[1]
  if (envStore.disableExpoNumber) {
    return toThousandSeparator(Number(v))
  }

  //當數值 -1< N <1 時, 小數點後後第5位開始有非為0 : 使用科學符號, 四捨五入到小數點第四位
  if (isGreaterThan && isLessThan && decimalPart?.length > 5) {
    temp = bignum.toExponential(defaultDecimalNumber)
  } else {
    // console.log('[temp]', temp)
    temp = toThousandSeparator(Number(v))
  }

  return temp
}

/**
 * 格式化百分比, 顯示到小數點第Ｎ位數
 * 後端回傳的值都是小數點, 用之前確認是否要先 mul(100)
 */
export const percentNumber = (v: any) =>
  BigNumber(v).round(defaultDecimalNumber) + ''

/**
 * 活動數據, 小數格式化 %
 * @param formatValue
 * @returns
 */
export const toLimitNumber = (formatValue: number | undefined) => {
  return not(isNil(formatValue))
    ? `${BigNumber(formatValue)
        .mul(100)
        .round(defaultDecimalNumber)
        .toNumber()}%`
    : undefined
}

/**
 * 轉換公噸
 * 1公噸= 1000公斤(kg)
 * @param formatValue
 * @returns numbers
 */
export const convertTon = (formatValue: any) => {
  return typeof formatValue === 'number'
    ? BigNumber(formatValue).div(1000).toNumber()
    : formatValue
}

/**
 *
 * 後端回傳的值都是 kg, 排放當量需轉換公噸
 * convertTon
 * @param formatValue
 * @returns
 */
export const toEquivalentEmissionFormat = (formatValue: number | undefined) => {
  const ton = convertTon(formatValue)
  return expoNumber(ton)
}

/**
 * 排放係數 小數點到第10位四捨五入
 */
export const toEmissionCoeffiFormat = (
  formatValue: number | undefined | null
) => {
  if (!formatValue) {
    return null
  }
  return numberFractionDigitsFormat(10)(Number(formatValue))
}

/**
 *
 * @returns number
 */
export const positiveNumber = (v: any) => {
  return BigNumber(v).round(defaultDecimalNumber).toNumber()
}

/**
 * 逸散排放 api 回傳的值, 前端要 * 100
 * @returns number
 */
export const vaporizeRateNumber = (v: any) => {
  return !isNil(v) ? BigNumber(v).mul(100).toNumber() : undefined
}

/**
 * 小數點轉百分比
 * @param value
 * @returns string | undefined
 */
export const convertPercentage = (
  value: number | undefined,
  decimalNumber = defaultDecimalNumber
) => {
  return not(isNil(value))
    ? `${BigNumber(value).mul(100).round(decimalNumber).toNumber()}%`
    : undefined
}

/**
 * 排放量 適用於數值大數時 至少 > 1, 不顯示科學符號
 * decimalNum: 顯示到小數點N位
 */
export const toEmissionFormatByDecimal = (
  value: number | undefined,
  decimalNum = 2
) => {
  if (!value) {
    return null
  }
  const ton = convertTon(value)

  return numberFractionDigitsFormat(decimalNum)(BigNumber(ton).toNumber())
}

/**
 * 數量顯示小數點N位, 不顯示科學符號
 * decimalNum: 顯示到小數點N位
 */
export const toNumberFormatByDecimal = (
  value: string | number | undefined,
  decimalNum = 2
) => {
  if (isNil(value)) {
    return ''
  }
  return numberFractionDigitsFormat(decimalNum)(BigNumber(value).toNumber())
}
