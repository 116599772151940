import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CloseCircleOutlined, WarningOutlined } from '@ant-design/icons'
import Button from '@components/ui/Button'
import Modal, { ModalProps } from '@components/ui/Modal'

interface Props extends ModalProps {
  onOk: () => void
  onCancel: () => void
  item: any
}

export default function PowerInputModal({ onCancel, onOk, ...props }: Props) {
  const { t } = useTranslation()

  return (
    <StyledModal
      visible
      width="600px"
      bodyStyle={{ padding: '60px 30px 20px' }}
      closeIcon={
        <CloseCircleOutlined
          onClick={onCancel}
          style={{ fontSize: '22px', color: '#000' }}
        />
      }
      onCancel={onCancel}
      footer={
        <div className="flex justify-between px-80">
          <Button
            key="btn-cancel"
            color="ghost"
            onClick={onCancel}
            style={{ minWidth: 80, height: 35 }}>
            {t('common:Cancel')}
          </Button>
          <Button
            key="btn-ok"
            color="primary"
            style={{ minWidth: 80, height: 35 }}
            onClick={onOk}>
            {t('common:confirm')}
          </Button>
        </div>
      }
      {...props}>
      <div className="flex justify-center items-center h-full w-full">
        <WarningOutlined
          style={{ fontSize: 51.4, color: '#ffa200' }}
          className="mr-17"
        />
        <Text>
          <Trans
            i18nKey="powerinput:tipBeforeAddRenewEngery_1"
            components={[<span className="text-red" />]}
          />
          <div className="mt-8">
            {t('powerinput:tipBeforeAddRenewEngery_2')}
          </div>
        </Text>
      </div>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 9px;
    background-color: ${p => p.theme.background};
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #707070;
  }
`

const Text = styled.div`
  font-size: 18px;
  color: #000;
`
