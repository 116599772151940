import Modal from '@components/ui/Modal/Modal'
import { CloseCircleOutlined } from '@ant-design/icons'
import Hr from '@components/ui/Hr'
import { ModalProps } from 'antd'
import { useTranslation } from 'react-i18next'
import Button from '@components/ui/Button'

export default function PageNoPermissionModal(props: ModalProps) {
  const { t } = useTranslation()

  return (
    <Modal
      {...props}
      maskClosable
      visible
      footer={<Button onClick={props.onOk}>{t('common:submit')}</Button>}
      closeIcon={
        <CloseCircleOutlined
          onClick={props.onCancel}
          style={{ fontSize: '22px', color: '#000' }}
        />
      }
      title={
        <div>
          <h2>{t('common:Auth_View_ActType')}</h2>
          <Hr />
        </div>
      }>
      <div>{t('common:No permission, please contact the administrator')}</div>
    </Modal>
  )
}
