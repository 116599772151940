import React from 'react'
import { Loading } from '@components/Loading'

const LazyLogin = React.lazy(
  () => import(/* webpackChunkName: "PreviewImagePage" */ './PreviewFilePage')
)

export default function PreviewFilePage() {
  return (
    <React.Suspense fallback={<Loading />}>
      <LazyLogin />
    </React.Suspense>
  )
}
