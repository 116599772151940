import React from 'react'
import cx from 'classnames'
import styled, { css } from 'styled-components'

export const RequiredMark = ({ className }: { className?: string }) => (
  <span
    className={cx('text-danger ml-2', className)}
    css={{ position: 'relative', top: 3 }}>
    *
  </span>
)

type Props = {
  fontWeight?: string | number
}

const BaseColumn = styled.div<Props>`
  padding: 7px 8px 7px 16px;
  font-size: 14px;
  border-bottom: none;

  :nth-child(odd) {
    color: ${p => p.theme.block.title};
  }

  :nth-child(even) {
    color: ${p => p.theme.block.text};
  }

  &.is--light-grey {
    background: ${p => p.theme.block.content};
  }

  ${p =>
    p.fontWeight &&
    css`
      font-weight: ${p.fontWeight};
    `}
`

interface ColumnProps {
  className?: string
  style?: React.CSSProperties
  required?: boolean
  fontWeight?: string | number
}

// type ColumnType = StyledComponent<'div', any, {}, never>

class Column extends React.Component<ColumnProps, any> {
  public static Dark: typeof DarkColumnComponent
  // public static Grey: ColumnType
  public static LightGrey: typeof LightGreyColumnComponent
  public static SilverGrey: typeof SilverGreyColumnComponent
  public static White: typeof WhiteColumnComponent
  // public static Warning: ColumnType

  public render() {
    return <BaseColumn {...this.props}>{this.props.children}</BaseColumn>
  }
}

const DarkColumn = styled(BaseColumn)`
  display: flex;
  align-items: center;
  width: 120px;
  background: ${p => p.theme.block.text} !important;
  border-right: 1px solid none;
  border-bottom: 1px solid #fff;
  color: #fff !important;
`

const LightGreyColumn = styled(BaseColumn)`
  display: flex;
  align-items: center;
  width: 120px;
  border-right: 1px solid none;
  background: ${p => p.theme.block.content};
`

const SilverGreyColumn = styled(BaseColumn)`
  display: flex;
  align-items: center;
  width: 120px;
  border-right: 1px solid none;
  color: #fff !important;
  background: ${p => p.theme.silverGrey};
`

const WhiteColumn = styled(BaseColumn)`
  display: flex;
  align-items: center;
  width: 120px;
  background: #fff;
  border-right: 1px solid none;
`

class DarkColumnComponent extends React.Component<ColumnProps, any> {
  public render() {
    const { required, ...props } = this.props
    return (
      <DarkColumn {...props}>
        {this.props.children}
        {required && <RequiredMark />}
      </DarkColumn>
    )
  }
}

class LightGreyColumnComponent extends React.Component<ColumnProps, any> {
  public render() {
    const { required, ...props } = this.props
    return (
      <LightGreyColumn {...props}>
        {this.props.children}
        {required && <RequiredMark />}
      </LightGreyColumn>
    )
  }
}

class SilverGreyColumnComponent extends React.Component<ColumnProps, any> {
  public render() {
    const { required, ...props } = this.props
    return (
      <SilverGreyColumn {...props}>
        {this.props.children}
        {required && <RequiredMark />}
      </SilverGreyColumn>
    )
  }
}

class WhiteColumnComponent extends React.Component<ColumnProps, any> {
  public render() {
    const { required, ...props } = this.props
    return (
      <WhiteColumn {...props}>
        {this.props.children}
        {required && <RequiredMark />}
      </WhiteColumn>
    )
  }
}

Column.Dark = DarkColumnComponent

Column.LightGrey = LightGreyColumnComponent

Column.SilverGrey = SilverGreyColumnComponent

Column.White = WhiteColumnComponent

export default Column
