import { SVGProps } from 'react'

const Company = ({ className, ...props }: SVGProps<any>) => (
  <svg
    className={`style-icon-company ${className}`}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_334_4494)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.56706 5.72648V16.8215H2.83372C2.58619 16.8215 2.34879 16.9198 2.17376 17.0948C1.99872 17.2699 1.90039 17.5073 1.90039 17.7548C1.90039 18.0023 1.99872 18.2397 2.17376 18.4148C2.34879 18.5898 2.58619 18.6881 2.83372 18.6881H17.8337C18.0813 18.6881 18.3187 18.5898 18.4937 18.4148C18.6687 18.2397 18.7671 18.0023 18.7671 17.7548C18.7671 17.5073 18.6687 17.2699 18.4937 17.0948C18.3187 16.9198 18.0813 16.8215 17.8337 16.8215H17.1004V5.85475C17.1002 5.48422 16.9835 5.12314 16.7668 4.82259C16.5501 4.52204 16.2443 4.29721 15.8928 4.17994L9.32618 1.99078C9.08438 1.91007 8.82797 1.88265 8.57456 1.9104C8.32115 1.93815 8.07673 2.02043 7.85811 2.15156L4.42477 4.21156C4.16317 4.36852 3.94667 4.59056 3.79636 4.85604C3.64605 5.12152 3.56705 5.4214 3.56706 5.72648ZM9.60039 4.05019L15.2337 5.9269V16.8215H9.60039V4.05019ZM5.43372 16.8215V5.78309L7.73372 4.40309V16.8215H5.43372Z"
        fill="#878787"
      />
    </g>
    <defs>
      <clipPath id="clip0_334_4494">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Company
