import { Spin } from 'antd'

type Props = {
  loading: boolean
  className?: string
  children?: React.ReactNode
}

const ContainerLoading = ({
  loading,
  className,
  children,
  ...props
}: Props) => {
  return (
    <div
      style={{ background: '#fff', borderRadius: '16px' }}
      className={className}
      {...props}>
      <Spin tip="Loading..." size="large" spinning={loading}>
        {children}
      </Spin>
    </div>
  )
}

export default ContainerLoading
