/**
 * 舊版 V1  style
 */
const { lighten, transparentize } = require('polished')

const primary = '#c9e3f8'
const secondary = '#e6edf1'
const info = '#5E9CE5'
const darkGrey = '#707070'
const darkGrey500 = 'rgba(74, 74, 74, 0.5)'
const darkBlue = '#44596c'
const whisper = '#e4e4e4'
const grey = '#efefef'
const lightBlue = '#b7d6e4'
const silverGrey = '#b8b8b8'
const light = '#fff'
const beige = '#fff1b8'
const green = '#019601'
const danger = '#d74c4c'
/**
 * @return { [key: string]: any}
 */
const theme = {
  loading: {
    color: '#0075d3',
  },
  disable: '#000',
  background: '#e7f3fd',
  adminBackground: '#dfe7bc',
  green,
  blue200: 'rgba(183, 220, 255, 0.2)',
  darkBlue,
  cyan: '#0ab1c1',
  blue: '#0075d3',
  danger,
  dark: '#222',
  dark100: 'rgba(0, 0, 0, 0.1)',
  darkGrey,
  grey,
  silverGrey,
  info, // (16, 94, 166)
  lightDark: '#333',
  lightGrey: '#e7e7e7',
  lightPink: '#ffeaea',
  light,
  primary,
  red100: '#ffdad6',
  red: '#ff0000',
  secondary,
  warning: primary,
  yellow100: beige,
  yellow: '#ffc247',
  btn: {
    text: '#000',
    bg: primary,
    bgHover: '#7894ab',
    outlineHover: 'rgba(0, 116, 168, 0.1)',
    linkHover: '#40a9ff',
    linkColor: '#1890ff',
    info: info,
    infoHover: 'rgba(94, 156, 229, 0.5)',
    border: '#707070',
    //
    shadow: '3px 3px 3px 0px rgba(0, 0, 0, 0.16)',
    ghost: {
      text: primary,
      border: primary,
      hover: transparentize(0.8, primary),
    },
    danger: {
      text: danger,
      border: danger,
      hover: transparentize(0.8, danger),
    },
    secondary: {
      border: '#707070',
      bg: '#fff',
      borderRadius: '4px',
      text: '#000',
      hoverBorder: '#82aaca',
      hoverBg: '#fff',
      icon: {
        color: '#000',
      },
    },
  },
  modal: {
    mask: 'rgba(0, 44, 86, 0.7)',
    maskDarken: 'rgba(0, 44, 86, 0.9)',
    border: info,
    title: '#000',
    close: {
      color: '#000',
      hover: lighten(0.2, '#000'),
    },
  },
  message: {
    color: darkGrey,
    bg: beige,
    fontSize: '16px',
    fontWeight: 500,
  },
  gray88: '#e0e0e0',
  whisper: whisper,
  table: {
    disabledRow: '#e7e7e7',
    headBg: 'transparent',
    head: '#000',
    headText: '#000',
    shape: 'none',
    bg: '#fff',
    bgHover: '#fffcf1',
    border: whisper,
    borderBottom: darkGrey,
    borderBottomHover: '#5e9ce5',
    borderHover: 'transparent',
    spacing: 0,
  },
  slider: '#c0973f',
  sliderBg: '#c0973f',
  // 套用所有分頁的樣式
  pagination: '#000',
  block: {
    text: grey,
    section: darkGrey,
    title: darkGrey,
    content: '#f4f3f3',
    borderBottom: info,
    borderRight: 'transparent',
  },
  sidemenu: primary,
  searchIcon: primary,
  font: {
    primary,
    secondary: grey,
  },
  icon: {
    primary: darkGrey500,
    primaryHover: darkGrey,
    secondary: '#fff',
  },
  menu: {
    hoverBg: '#fff',
    activeBg: '#fff',
    boxShadow: 'unset',
  },
  lightBlue,
  checkbox: {
    border: '#000',
    bg: '#000',
    text: '#000',
    color: '#fff',
    indeterminate: '#000',
  },
  darkGrey500,
  tooltip: {
    color: '#4a4a4a',
    bg: '#fffcf1',
  },
  gamboge: '#b68116',
  menuInlineTooltip: {
    bg: '#fff',
    color: grey,
    active: primary,
  },
  radio: {
    size: '20px',
    primary: '#284257',
    borderWidth: '3px',
    textSize: '20px',
  },
  report: {
    primary: '#073963',
  },
  crTarget: {
    green: '#00afb9',
    darkBlue: '#264653',
  },
}

module.exports = theme
