import { ActTypeEnum } from './act.enum'

export const FuncAuthCode = { EMPLOYEE_COMMUTE: 'EMPLOYEE_COMMUTE' }

export const ActCode = {
  /**
   * 固定式燃燒源
   */
  [ActTypeEnum.StationaryCombustion]: 'ACT_FIXED_COMBUSTION',
  /**
   * 移動式燃燒源
   */
  [ActTypeEnum.MovableCombustion]: 'ACT_MOBILE_COMBUSTION',
  /**
   * 製程排放
   */
  [ActTypeEnum.ProcessEmissions]: 'ACT_PROCESS_EMISSION',
  /**
   * 逸散排放
   */
  [ActTypeEnum.FugitiveEmission]: 'ACT_VAPORIZE_EMISSION',
  /**
   * 土地變更
   */
  [ActTypeEnum.LandChanged]: 'ACT_LAND_CHANGE',
  /**
   * 輸入電力
   */
  [ActTypeEnum.PowerInput]: 'ACT_INPUT_POWER',
  /**
   * 輸入蒸氣
   */
  [ActTypeEnum.SteamInput]: 'ACT_INPUT_STEAM',
  /**
   *   貨物運輸
   */
  [ActTypeEnum.Shipment]: 'ACT_GOODS_TRANS',
  /**
   * 員工出勤
   */
  [ActTypeEnum.Attendance]: 'ACT_EMPLOYEE_ATTENDANCE',
  /**
   * 員工差旅
   */
  [ActTypeEnum.Trip]: 'ACT_EMPLOYEE_TRAVEL',
  /**
   *  訪客紀錄
   */
  [ActTypeEnum.Visitor]: 'ACT_GUEST_RECORD',
  /**
   * 採購貨物
   */
  [ActTypeEnum.PurchaseGoods]: 'ACT_PURCHASE_GOODS',
  /**
   * 資產管理
   */
  [ActTypeEnum.Assets]: 'ACT_ASSET_MANAGEMENT',
  /**
   * 廢棄物處理
   */
  [ActTypeEnum.WasteDisposal]: 'ACT_SCRAP',
  /**
   * 其他能源活動
   */
  [ActTypeEnum.AlternativeEnergy]: 15,
  /**
   * 資產租賃
   */
  [ActTypeEnum.RentalAssets]: 16,
  /**
   * 購買服務
   */
  [ActTypeEnum.PurchaseServices]: 17,
  /**
   * 用水資料
   */
  [ActTypeEnum.WaterUsage]: 'ACT_WATER_USAGE',
  /**
   * 產品週期
   */
  [ActTypeEnum.ProductCycle]: 'ACT_PROD_LIFE_CYCLE',
  /**
   * 下游租賃資產
   */
  [ActTypeEnum.DownstreamLeasedAssets]: 'ACT_ASSET_LEASE_FROM_DOWN',
  /**
   * 投資管理
   */
  [ActTypeEnum.Investment]: 'ACT_INVESTMENT',
  /**
   * 其他
   */
  [ActTypeEnum.Other]: 'ACT_OTHERS',
  /**
   *   售出產品的使用
   */
  [ActTypeEnum.SoldProduct]: 'ACT_PROD_USAGE',
  /**
   *   售出產品最終處理
   */
  [ActTypeEnum.SoldProductWaste]: 'ACT_PROD_TERMINATED',
  /**
   *   融資排放
   */
  [ActTypeEnum.FinancedEmissions]: 'ACT_FINANCING',
  /**
   * 廢水處理
   */
  [ActTypeEnum.WasteWaterDisposal]: 'ACT_SCRAP_WATER',
  //綠電憑證
  [ActTypeEnum.GreenElectricityCert]: 27,
  //自發電力
  [ActTypeEnum.SelfPower]: 28,
  [FuncAuthCode.EMPLOYEE_COMMUTE]: 'EMPLOYEE_COMMUTE',
}
