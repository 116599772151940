import { useRoutes, useAppRoutes } from './useRoutes'

export { useRoutes, useAppRoutes }

export type { RouteItem, Route } from './usePrepareRoutes'

export { default as history } from './history'

export * from './routes'

export * from './use-link-to'

export default useRoutes
