import React from 'react'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import styled from 'styled-components'

const StyledTable: any = styled(Table)`
  &.ant-table-wrapper {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 10px 20px;
    margin-top: 10px;
    overflow: auto;

    .ant-table table {
      border-spacing: 0 ${p => p.theme.table.spacing}px;
    }

    &.pagination--none .ant-table-pagination {
      display: none;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border-color: ${p => p.theme.pagination};
      border-radius: 6px;
      color: ${p => p.theme.pagination};
      .anticon {
        vertical-align: 0.125em;
      }
    }

    .ant-pagination-item {
      border-color: ${p => p.theme.pagination};
    }

    .ant-pagination-item a {
      color: ${p => p.theme.pagination};
    }

    .ant-pagination-item-active,
    .ant-pagination-item-active:focus,
    .ant-pagination-item:hover {
      border-color: ${p => p.theme.pagination};
      background: ${p => p.theme.pagination};

      a {
        color: #fff;
      }
    }

    .ant-table-pagination.ant-pagination {
      margin-top: 32px;
    }

    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: ${p => p.theme.pagination};
      background: ${p => p.theme.pagination};
      color: #fff;
    }

    .ant-pagination-simple-pager {
      color: ${p => p.theme.pagination};
    }
  }

  .ant-table-thead {
    line-height: 1em;

    > tr {
      background: transparent;
    }

    > tr > th {
      font-size: 13px;
      font-weight: 800;
      padding-bottom: 6px;
      color: ${p => p.theme.table.headText};
      background: ${p => p.theme.table.headBg};
      border-bottom-color: ${p => p.theme.table.borderBottom};
      word-break: keep-all;
      white-space: nowrap;
      &::before {
        display: none !important;
      }
    }
  }

  .ant-table-tbody {
    > tr {
      &.disabled-row {
        > td {
          background: ${p => p.theme.table.disabledRow} !important;
        }
      }
      > td {
        height: 48px;
        padding-top: 7px;
        padding-bottom: 7px;
        line-height: 1em;
        background: ${p => p.theme.table.bg};
        color: ${p => p.theme.table.text};
        border-top: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
        border-bottom: 1px solid ${p => p.theme.table.borderBottom} !important;
        white-space: nowrap;
      }
    }

    /* > tr > td:first-child {
      border-left: 1px solid transparent;
    } */

    /* > tr > td:last-child {
      border-right: 1px solid transparent;
    } */

    > tr:hover:not(.ant-table-expanded-row),
    > tr.hover-style {
      background: ${p => p.theme.table.bgHover};

      > td {
        border-top: 1px solid ${p => p.theme.table.borderHover};
        border-bottom: 1px solid ${p => p.theme.table.borderBottom};
      }

      > td:first-child {
        border-left: 1px solid ${p => p.theme.table.borderHover};
      }

      > td:last-child {
        border-right: 1px solid ${p => p.theme.table.borderHover};
      }
    }
  }

  &.is--striped .ant-table-tbody {
    > tr > td {
      background: transparent;
    }

    > tr:nth-child(odd) {
      background: ${p => p.theme.blue200};
    }
  }

  &.is--small .ant-table-tbody {
    > tr > td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &.is--xs {
    .ant-table-thead {
      > tr > th {
        padding-left: 4px;
        padding-right: 4px;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .ant-table-tbody {
      > tr > td {
        height: 32px;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .ant-pagination {
      margin-bottom: 0;
    }
  }

  &.pager--none {
    .ant-pagination {
      display: none;
    }
  }

  &.is--editable .ant-table-tbody {
    > tr > td {
      padding-top: 19px;
      padding-bottom: 19px;
    }
  }

  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: ${p => p.theme.table.bgHover};
  }

  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: ${p => p.theme.table.bgHover};
  }

  .ant-table-tbody > tr.ant-table-row-selected td {
    background: ${p => p.theme.yellow100};
    border-color: ${p => p.theme.darkGrey};
  }

  .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${p => p.theme.checkbox.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${p => p.theme.checkbox.bg};
    border-color: ${p => p.theme.checkbox.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid ${p => p.theme.checkbox.color};
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${p => p.theme.checkbox.indeterminate};
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 0 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  }

  .ant-form-explain {
    font-size: 13px;
  }

  .has-error .ant-form-explain {
    position: absolute !important;
    font-size: 12px !important;
  }
`

class TableComponent<T extends any> extends React.Component<TableProps<T>> {
  static Column = Table.Column
  render() {
    return <StyledTable {...this.props}>{this.props.children}</StyledTable>
  }
}

export default TableComponent
