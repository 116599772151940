import { compose, pathOr, takeLast } from 'ramda'
import Checkbox from './Checkbox'

const CheckboxGroupSingle = (
  props: React.PropsWithChildren<{
    onChange?: (value: any) => void
    value?: any
  }>
) => {
  const { onChange, value = [], children } = props

  return (
    <Checkbox.Group
      value={Array.isArray(value) ? value : [value]}
      onChange={(v: any[]) => {
        onChange &&
          onChange(compose(pathOr(undefined, [0]), takeLast(1))(v as any))
      }}>
      {children}
    </Checkbox.Group>
  )
}

export default CheckboxGroupSingle
