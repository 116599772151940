import { SVGProps } from 'react'
import cx from 'classnames'
import theme from '@assets/theme'

const LocationSVG = ({
  className,
  color = theme.icon.primary,
  ...props
}: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    className={cx('svg-icon icon-location', className)}
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12 5.337a4.71 4.71 0 0 0-3.347 1.385 4.701 4.701 0 0 0-1.385 3.347c0 1.265.492 2.451 1.385 3.347A4.701 4.701 0 0 0 12 14.8a4.705 4.705 0 0 0 3.347-1.385 4.701 4.701 0 0 0 1.385-3.347 4.705 4.705 0 0 0-1.385-3.347A4.71 4.71 0 0 0 12 5.337zm0 7.79a3.06 3.06 0 0 1-3.059-3.058A3.06 3.06 0 0 1 12 7.01a3.06 3.06 0 0 1 3.059 3.059A3.06 3.06 0 0 1 12 13.127zm8.405-6.808a8.216 8.216 0 0 0-2.009-2.553 9.572 9.572 0 0 0-2.916-1.715 9.913 9.913 0 0 0-3.478-.628h-.004c-1.191 0-2.36.21-3.478.628a9.572 9.572 0 0 0-2.916 1.715A8.209 8.209 0 0 0 3.595 6.32a6.995 6.995 0 0 0-.75 3.138c0 1.507.37 3.056 1.102 4.605.605 1.283 1.46 2.576 2.545 3.847 1.94 2.273 4.125 3.9 4.974 4.493a.927.927 0 0 0 1.068 0c.849-.593 3.036-2.22 4.974-4.493 1.083-1.27 1.94-2.564 2.545-3.846.731-1.55 1.102-3.101 1.102-4.606 0-1.085-.253-2.14-.75-3.138zm-.926 3.14c0 1.259-.316 2.57-.942 3.893-.537 1.144-1.313 2.311-2.302 3.471-1.544 1.812-3.274 3.197-4.235 3.903-.96-.708-2.69-2.09-4.235-3.903-.99-1.16-1.765-2.33-2.304-3.473-.626-1.324-.942-2.634-.942-3.893 0-.825.192-1.631.574-2.393A6.58 6.58 0 0 1 6.698 5.03a7.876 7.876 0 0 1 2.405-1.416 8.283 8.283 0 0 1 2.895-.523h.004c.99 0 1.964.176 2.893.523.897.335 1.706.811 2.407 1.416a6.47 6.47 0 0 1 1.603 2.034c.382.769.574 1.573.574 2.395z"
      />
    </g>
  </svg>
)

export default LocationSVG
