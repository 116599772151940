import React from 'react'
import { Col, Row, Spin, Form } from 'antd'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Hr from '@components/ui/Hr'
import Button from '@components/ui/Button'
import Layout, { H3 } from '@components/ui/Layout'
import { InputNumber } from '@components/ui/Input'
import { MutationOptions } from '@/services'
import { useAssessmentList, useAddAssessment } from '@/assessment'
import type { IAssessment } from '@/assessment'
import { rules } from '@lib/utils/form'
import { ActionTypes } from '@lib/auth'
import useRouteAuth from '@/routes/useRouteAuth'
import CategoryTable from './CategoryTable'
import { useTranslation } from 'react-i18next'

const AssessCategoriesManagement = React.memo(() => {
  const history = useHistory()
  const [form] = Form.useForm()

  const [, checkIsAuthenticated] = useRouteAuth()

  const [includeRating, setIncludeRating] = React.useState<undefined | number>(
    undefined
  )

  const [isErrorRating, setIsErrorRating] = React.useState(false)

  const { dataSource, isLoading, refetch } = useAssessmentList()

  const mutationOptions: MutationOptions = {
    onSuccess: () => {
      refetch()
      history.goBack()
    },
  }

  const [creating, handleAdd] = useAddAssessment(mutationOptions)

  const handleSave = (values: any) => {
    if (!includeRating) {
      setIsErrorRating(true)
      return
    }

    const list = Object.values(values) as IAssessment[]
    const acts = list.map(x => {
      const { items, ...others } = x
      const details = items.map(y => {
        const idx = y.details.findIndex(z => z.checked)

        return { id: y.id, idx: idx > -1 ? idx : null }
      })

      return { ...others, details }
    })

    const parmas = { acts, standardValue: includeRating }
    // console.log('[parmas]', parmas)
    handleAdd(parmas)
  }

  const handleIncludeRating = (v: any) => {
    setIncludeRating(v)
    v ? setIsErrorRating(false) : setIsErrorRating(true)
  }

  React.useEffect(() => {
    setIncludeRating(dataSource?.standardValue)
  }, [dataSource?.standardValue])

  const creatable = React.useMemo(
    () =>
      checkIsAuthenticated(
        ActionTypes.CREATABLE,
        `${history.location.pathname.replace('/categories', '')}`
      ),
    []
  )

  const { t } = useTranslation()

  return (
    <Spin spinning={isLoading}>
      <LayoutStyled>
        <Form form={form} preserve={false} onFinish={handleSave}>
          <Row justify="space-between">
            <Col className="flex ">
              <H3 className="m-0">{t('common:assessment of each item')}</H3>
              <div className="flex items-end mx-15 pb-5">
                {t('common:inclusion criteria')}
                <InputNumber
                  disabled={!creatable}
                  value={includeRating}
                  {...(isErrorRating ? { status: 'error' } : {})}
                  className="w-80 mx-5"
                  min={0}
                  onChange={handleIncludeRating}
                />
                {t('common:point')}
                {isErrorRating && (
                  <span className="ml-8 text-danger">
                    {rules.required.message}
                  </span>
                )}
              </div>
            </Col>

            <Col>
              <Button
                color="ghost"
                className="mr-15"
                onClick={() => history.goBack()}>
                {creatable ? t('common:Cancel') : t('common:Back')}
              </Button>
              {creatable && (
                <Button
                  loading={creating}
                  color="primary"
                  onClick={form.submit}>
                  {t('common:Save')}
                </Button>
              )}
            </Col>
          </Row>
          <Hr type="solid" />
          {dataSource?.data?.content.map((i: IAssessment, idx: number) => (
            <>
              <CategoryTable
                key={idx}
                actType={i.actType}
                category={i}
                form={form}
                includeRating={includeRating}
              />
              <Hr type="dashed" />
            </>
          ))}
        </Form>
      </LayoutStyled>
    </Spin>
  )
})

const LayoutStyled = styled(Layout)`
  border-radius: 10px;
  background-color: #fff;
  padding: 13px 14px;
`

export default AssessCategoriesManagement
