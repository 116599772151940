import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import { useAuth } from '@/auth'
import useViewport from '@/hooks/useViewport'
import usePrepareRoutes from './usePrepareRoutes'
import type { RouteItem } from './usePrepareRoutes'
import useCompanyUrl from '@/hooks/useCompanyUrl'
import { useTheme } from 'styled-components'

const getUrlName = (routes: RouteItem[]) => {
  let result: { [key: string]: RouteItem } = {}

  const traversal = (node: RouteItem) => {
    if (!node) {
      return
    }
    if (node.path) {
      result[node.path] = node
    }
    if (node.children) {
      node.children.forEach(traversal)
    }
  }

  routes.forEach(traversal)

  return result
}

export function useRoutes(routes: { [key: string]: any }) {
  const _routes = usePrepareRoutes()(routes)

  return {
    _routes,
    routeByUrl: getUrlName(_routes),
  }
}

export function useAppRoutes() {
  const companyUrl = useCompanyUrl()

  const renderRouteItem = React.useCallback(
    (item: RouteItem) => {
      const path = `${item.path}${item.param ? `/${item.param}` : ''}`

      if (!path) {
        return
      }

      if (!item.readable) {
        return
      }

      return (
        <Route
          exact
          key={item.id}
          path={companyUrl.urlReplacer(path)}
          component={
            item.component ? item.component : () => <h1>{item.title}</h1>
          }
        />
      )
    },
    [companyUrl]
  )

  const renderRoute: any = React.useCallback(
    ({ data }: { data: RouteItem[] }) => {
      return data.map((item: RouteItem) => {
        return [
          renderRouteItem(item),
          item.children && renderRoute({ data: item.children }),
          item.hide && renderRoute({ data: item.hide }),
        ]
      })
    },
    [renderRouteItem]
  )

  const { isAuthenticated } = useAuth()
  const history = useHistory()
  const { isMobile } = useViewport()

  const [currentUrl, setCurrentUrl] = React.useState(history.location.pathname)

  history.listen(() => setCurrentUrl(history.location.pathname))

  const them: any = useTheme()

  const layoutStyle = React.useMemo(() => {
    return {
      background: them.containerBg,
    }
  }, [them])

  return {
    isMobile,
    isAuthenticated,
    currentUrl,
    layoutStyle,
    renderRoute,
  }
}

export default useRoutes
