import { SVGProps } from 'react'

const User = ({ className, ...props }: SVGProps<any>) => (
  <svg
    className={`style-icon-user ${className}`}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_336_1771)">
      <path
        d="M9.99906 0.664673C4.84614 0.664673 0.664062 4.84675 0.664062 9.99967C0.664062 15.1526 4.84614 19.3347 9.99906 19.3347C15.152 19.3347 19.3341 15.1526 19.3341 9.99967C19.3341 4.84675 15.152 0.664673 9.99906 0.664673ZM5.65829 16.0674C6.88117 15.1899 8.37477 14.6672 9.99906 14.6672C11.6234 14.6672 13.117 15.1899 14.3398 16.0674C13.117 16.9449 11.6234 17.4677 9.99906 17.4677C8.37477 17.4677 6.88117 16.9449 5.65829 16.0674ZM15.7308 14.7792C14.0956 13.4963 12.0774 12.7991 9.99906 12.7991C7.92074 12.7991 5.9025 13.4963 4.26737 14.7792C3.1443 13.4399 2.52948 11.7475 2.53106 9.99967C2.53106 5.8736 5.87299 2.53167 9.99906 2.53167C14.1251 2.53167 17.4671 5.8736 17.4671 9.99967C17.4671 11.82 16.8136 13.4816 15.7308 14.7792Z"
        fill="#878787"
      />
      <path
        d="M9.99967 4.39893C8.19802 4.39893 6.73242 5.86452 6.73242 7.66618C6.73242 9.46783 8.19802 10.9334 9.99967 10.9334C11.8013 10.9334 13.2669 9.46783 13.2669 7.66618C13.2669 5.86452 11.8013 4.39893 9.99967 4.39893ZM9.99967 9.06643C9.22487 9.06643 8.59942 8.44098 8.59942 7.66618C8.59942 6.89137 9.22487 6.26593 9.99967 6.26593C10.7745 6.26593 11.3999 6.89137 11.3999 7.66618C11.3999 8.44098 10.7745 9.06643 9.99967 9.06643Z"
        fill="#878787"
      />
    </g>
    <defs>
      <clipPath id="clip0_336_1771">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default User
