import { SVGProps } from 'react'

const CloseSVG = ({ color = '#fff', ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H32V32H0z"
                transform="translate(-976 -266) translate(416 250) translate(560 16)"
              />
            </g>
            <path
              fill={color}
              fillRule="nonzero"
              d="M17.73 16l7.911-7.91c.479-.479.479-1.253 0-1.728-.478-.479-1.251-.479-1.726 0L16 14.27 8.085 6.359c-.478-.479-1.251-.479-1.726 0-.479.478-.479 1.252 0 1.728l7.911 7.91-7.911 7.915c-.479.478-.479 1.252 0 1.728.239.239.552.357.863.357.313 0 .624-.118.863-.357L16 17.728l7.915 7.915c.239.239.55.357.863.357.314 0 .624-.118.863-.357.479-.479.479-1.253 0-1.728l-7.911-7.914z"
              transform="translate(-976 -266) translate(416 250) translate(560 16)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CloseSVG
