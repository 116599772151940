import { SVGProps } from 'react'

const Cate1 = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_640_4473)">
      <path
        d="M14.166 15H14.9993M9.99935 15H10.8327M5.83268 15H6.66602M1.66602 16.6666C1.66602 17.1087 1.84161 17.5326 2.15417 17.8451C2.46673 18.1577 2.89065 18.3333 3.33268 18.3333H16.666C17.108 18.3333 17.532 18.1577 17.8445 17.8451C18.1571 17.5326 18.3327 17.1087 18.3327 16.6666V6.66663L12.4993 10.8333V6.66663L6.66602 10.8333V3.33329C6.66602 2.89127 6.49042 2.46734 6.17786 2.15478C5.8653 1.84222 5.44138 1.66663 4.99935 1.66663H3.33268C2.89065 1.66663 2.46673 1.84222 2.15417 2.15478C1.84161 2.46734 1.66602 2.89127 1.66602 3.33329V16.6666Z"
        stroke="#878787"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_640_4473">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Cate1
