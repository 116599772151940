import i18n from 'i18next'

export const UserUsageStatusEnums = [
  {
    pid: 1,
    parametertext: i18n.t('common:active'),
    parametervalue: 1,
  },
  {
    pid: 2,
    parametertext: i18n.t('common:inactive'),
    parametervalue: 0,
  },
]
