import history from '@/routes/history'
import toQueryString from '@/utils/toQueryString'

type Props = {
  url?: string
  params?: any
}

export function useLinkTo({ url = '/', params = {} }: Props = {}) {
  const handleTo = (evt: React.MouseEvent<HTMLElement>) => {
    history.push(
      `${url}?id=${evt.currentTarget.dataset.id}&${toQueryString(params)}`
    )
  }

  return handleTo
}

export default useLinkTo
