import H1 from './H1'

const PageHeader = ({
  sub = '',
  title = '',
}: {
  sub?: string
  title?: string
}) => (
  <H1 className="mb-0">
    {sub ? (
      <>
        <span className="sub-title">
          {sub} {'>'}
        </span>{' '}
        {title}
      </>
    ) : (
      title
    )}
  </H1>
)

export default PageHeader
