import React from 'react'
import { useQuery } from 'react-query'
import { path } from 'ramda'
import request, {
  Mutation,
  MutationOptions,
  useLazyQuery,
  useMakeMutation,
  QueryVariables,
} from '@/services'
import useComapnyCode from '@/hooks/useComapnyCode'
import type { Spec, SpecDetail } from './announce.type'
import { isNotNil } from '@/utils/webHelper'

export const useSpecList = ({ variables = {}, ...options }: any = {}) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, refetch, isLoading, ...rest } = useQuery<Spec[]>(
    [
      '/{companyCode}/coefficient/announceCoefficientSpec/list',
      { companyCode },
    ],
    {
      enabled: !!companyCode,
      select: res => path<Spec>(['data'], res),
      ...options,
    }
  )

  const defaultSpecSeq = React.useMemo(() => {
    if (isNotNil(data)) {
      return data?.find((i: Spec) => i.isDefault)?.id
    }
  }, [data])

  return {
    ...rest,
    refetch,
    loading: isLoading,
    dataSource: (data as Spec[]) || [],
    defaultSpecSeq,
  }
}

export const useSpecDetail = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, refetch, isLoading, ...others } = useLazyQuery(
    [
      '/{companyCode}/coefficient/announceCoefficientSpec/{id}',
      { companyCode, ...variables },
    ],
    {
      enabled: !!variables.id,
      select: res => path<SpecDetail>(['data'], res),
      refetchOnMount: true,
      ...options,
    }
  )

  return {
    ...others,
    refetch,
    querySpecDetail: refetch,
    loading: isLoading,
    dataSource: (data as SpecDetail) || {},
  }
}

export const useCreateSpec = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request(`/${companyCode}/coefficient/announceCoefficientSpec`, {
        method: 'POST',
        body: {
          ...body,
        },
      }),
    options
  )
}

export const useUpdateAllSpecList = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request(`/${companyCode}/coefficient/announceCoefficientSpec/all`, {
        method: 'PUT',
        body: {
          ...body,
        },
      }),
    options
  )
}

export const useApplySpec = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })
  return useMakeMutation(
    (body: Spec) =>
      request(
        `/${companyCode}/coefficient/announceCoefficientSpec/apply/{id}`,
        {
          method: 'POST',
          body,
        }
      ),
    options
  )
}

export const useCreateSpecDetail = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: Spec) =>
      request(`/${companyCode}/coefficient/announceCoefficient`, {
        method: 'POST',
        body: {
          ...body,
        },
      }),
    options
  )
}

export const useUpdateSpecDetail = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request(`/${companyCode}/coefficient/announceCoefficient/{id}`, {
        method: 'PUT',
        body: {
          ...body,
        },
      }),
    options
  )
}

export const useUpdateSpecStartDate = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: Spec) =>
      request(`/${companyCode}/coefficient/announceCoefficientSpec/interval`, {
        method: 'POST',
        body: {
          ...body,
        },
      }),
    options
  )
}

export const useDeleteSpecDetail = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request(`/${companyCode}/coefficient/announceCoefficient/{id}`, {
        method: 'DELETE',
        body: { ...body, companyCode },
      }),
    options
  )
}
// 檢視係數大表
export const useAnnounceCoeffiDetail = ({
  variables = {},
  ...options
}: any = {}) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, ...rest } = useQuery<any[]>(
    [
      '/{companyCode}/coefficient/announceCoefficient/content/{id}',
      { companyCode, ...variables },
    ],
    {
      enabled: !!companyCode && !!variables?.id,
      select: res => path(['data'], res),
      ...options,
    }
  )

  const convertData = React.useMemo(() => {
    let temp: any = []
    if (data) {
      data?.forEach((r: any[], rIdx: number) => {
        let obj = {} as any

        if (rIdx !== 0) {
          r.forEach((x: string, idx: number) => (obj[`col-${idx}`] = x))
          return temp.push({ id: rIdx, ...obj })
        } else {
          return temp.push(r)
        }
      })
    }

    return temp
  }, [data])

  return {
    ...rest,
    tableTitle: data && data[0],
    tableDataSource: convertData.slice(1),
  }
}
