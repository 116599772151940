import { SVGProps } from 'react'

const Customer = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M26.667 0A3.343 3.343 0 0 1 30 3.333v23.334A3.343 3.343 0 0 1 26.667 30H3.333A3.333 3.333 0 0 1 0 26.667V3.333A3.333 3.333 0 0 1 3.333 0zM9.72 12.774H7.8c-2.577 0-4.679 2.055-4.795 4.64V17.627l-.001.11-.001.826v.212l-.001 1.232v.272L3 22.285v.287L3 26h11.52l-.001-3.429v-.286l-.001-2.005v-.272l-.001-1.232V17.868l-.001-.13v-.264l-.001-.06c-.118-2.583-2.22-4.64-4.795-4.64zm12.48 2.923h-1.92c-2.577 0-4.679 2.055-4.795 4.64v.155l-.001.106v.455l-.001.19v.657l-.001.241v1.289l-.001.26L15.48 26H27v-2.055l-.001-.256v-1.298l-.001-.25-.001-.898v-.514l-.001-.13v-.24l-.001-.022c-.118-2.582-2.22-4.64-4.795-4.64zm-.96-8.774c-2.122 0-3.843 1.745-3.843 3.898s1.72 3.898 3.843 3.898c2.122 0 3.843-1.745 3.843-3.898s-1.72-3.898-3.843-3.898zM8.76 4C6.638 4 4.917 5.745 4.917 7.898s1.72 3.898 3.843 3.898c2.122 0 3.843-1.745 3.843-3.898S10.883 4 8.76 4z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default Customer
