import { downloadFile } from '@/utils/webHelper'
import { useQuery } from 'react-query'
import React from 'react'
import useComapnyCode from '@/hooks/useComapnyCode'
import request from '@/services'
import toQueryString from '@/utils/toQueryString'

const useExportQuery = (apiUrl: string, query: any, options: any) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })
  const { isNotSupportAdmin, ...otherOptions } = options
  return useQuery(
    [apiUrl, query],
    () => {
      return request(
        `${isNotSupportAdmin ? '' : `/${companyCode}`}${apiUrl}?${toQueryString(
          query
        )}`,
        {
          method: 'GET',
          headers: {},
        }
      )
    },
    {
      enabled: !!query,
      ...otherOptions,
    }
  )
}

export function useExportFile({
  filename,
  apiUrl,
  extension,
  isNotSupportAdmin = false,
}: {
  filename: string
  apiUrl: string
  extension?: string
  isNotSupportAdmin?: boolean
}) {
  const [querys, setQuerys] = React.useState<Record<string, any> | undefined>(
    undefined
  )
  const [exportKey, setExportKey] = React.useState(0)

  const exportSuccess = (data: Blob) => {
    downloadFile({
      blob: data,
      filename,
      extension,
    })
  }

  const { isLoading } = useExportQuery(apiUrl, querys, {
    isNotSupportAdmin,
    onSuccess: (data: any) => {
      exportSuccess(data)
    },
  })

  const exportFile = (querys: Record<string, any> | undefined) => {
    setQuerys({
      ...querys,
      exportKey,
    })
    setExportKey(k => ++k)
  }

  return {
    exportFile,
    isExporting: isLoading,
  }
}
