import packageInfo from '../../package.json'
import { AES } from 'crypto-js'
import CryptoEncUtf8 from 'crypto-js/enc-utf8'

const secretKey = packageInfo?.name || 'SmartPoleSystem'

export const encrypt = (text: string = '') => (
  AES.encrypt(text, secretKey).toString()
)

export const decrypt = (text: string = '') => {
  try {
    return AES.decrypt(text, secretKey).toString(CryptoEncUtf8)
  } catch (e) {
    console.error(e)
    return ''
  }
}
