import { SVGProps } from 'react'

const Clipboard = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M23.438 3.75H18.75a3.75 3.75 0 0 0-7.5 0H6.563A2.813 2.813 0 0 0 3.75 6.563v20.625A2.813 2.813 0 0 0 6.563 30h16.875a2.813 2.813 0 0 0 2.812-2.812V6.563a2.813 2.813 0 0 0-2.812-2.813zM15 2.344a1.406 1.406 0 1 1 0 2.812 1.406 1.406 0 0 1 0-2.812zm7.1 13.582-8.377 8.308a.7.7 0 0 1-1-.006l-4.84-4.881a.7.7 0 0 1 .006-1L9.557 16.7a.7.7 0 0 1 1 .006l2.7 2.719 6.211-6.164a.7.7 0 0 1 1 .006l1.652 1.664a.7.7 0 0 1-.018.995H22.1z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default Clipboard
