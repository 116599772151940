const Authority = ({ ...props }) => {
  return (
    <i className="icon-authority" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20">
        <path
          fill="#878787"
          d="M16.716 2C17.424 2 18 2.577 18 3.286v6.071c0 .356-.287.643-.642.643-.355 0-.642-.287-.642-.643V3.286H3.284v13.428h4.941c.355 0 .642.288.642.643 0 .355-.287.643-.642.643h-4.94C2.575 18 2 17.423 2 16.714V3.286C2 2.576 2.576 2 3.284 2zm-2.159 8.334c1.299 0 2.355 1.057 2.355 2.357 0 .82-.42 1.541-1.054 1.964 1.068.433 1.887 1.384 2.112 2.574.066.348-.163.684-.51.751-.04.008-.08.011-.12.011-.301 0-.57-.214-.63-.523-.194-1.032-1.1-1.78-2.15-1.78-1.051 0-1.956.748-2.152 1.78-.066.35-.403.578-.75.512-.35-.066-.579-.403-.513-.751.225-1.188 1.044-2.141 2.112-2.574-.635-.423-1.054-1.146-1.054-1.964 0-1.3 1.056-2.357 2.354-2.357zm0 1.286c-.59 0-1.07.48-1.07 1.071s.48 1.071 1.07 1.071c.59 0 1.07-.48 1.07-1.07 0-.592-.48-1.072-1.07-1.072zm-6.332.666c.355 0 .642.287.642.643 0 .355-.287.642-.642.642H4.943c-.355 0-.642-.287-.642-.642 0-.356.287-.643.642-.643zm0-3.429c.355 0 .642.288.642.643 0 .355-.287.643-.642.643H4.943c-.355 0-.642-.288-.642-.643 0-.355.287-.643.642-.643zm2.283-3.428c.355 0 .643.287.643.642 0 .356-.288.643-.643.643H4.943c-.355 0-.642-.287-.642-.643 0-.355.287-.642.642-.642z"/>
      </svg>
    </i>
  )
}

export default Authority
