import moment from 'moment-timezone'
const clientTz = moment.tz.guess()

/**
 * @desc 用 antd Datepicker,  moment 要轉成 預設時區 TW
 * @param str
 * @returns millionsec
 */
export const convertTimezoneMS = (str: moment.Moment, subfix = '00:00:00') => {
  // from antd moment
  // console.log('[str.tz]', !str.tz, str?.tz())
  if (str?.tz() === 'Asia/Taipei') {
    const localDate = moment(str).format('YYYY-MM-DD')
    const twDate = moment(`${localDate} ${subfix}`)
    return twDate.valueOf()
  }

  // 轉區域的時間
  const localDate = moment(str).tz(clientTz).format('YYYY-MM-DD')
  const twDate = moment(`${localDate} ${subfix}`).tz('Asia/Taipei')
  return twDate.valueOf()
}
/**
 * api 轉 moment
 */
export const toMomentRangeDate = (
  str: number[] | string[] | undefined[],
  fname: string
) => {
  const [startAt, endAt] = str
  return {
    [fname]: [
      startAt ? moment(startAt) : undefined,
      endAt ? moment(endAt) : undefined,
    ],
  }
}
// 儲存區域時間
export const toDateMS = (str?: moment.Moment) => {
  if (!str) {
    return undefined
  }
  const ms = convertTimezoneMS(str)
  return ms
}
