import { useQuery, UseQueryOptions } from 'react-query'
import { prop } from 'ramda'
import request, { Mutation, MutationOptions, useMakeMutation } from '@/services'
import useComapnyCode from '@/hooks/useComapnyCode'
import toQueryString from '@/utils/toQueryString'

export const useGetSSOLoginURL = (options?: UseQueryOptions<string>) => {
  const companyCode = useComapnyCode()

  const { data, isLoading, refetch } = useQuery<string>(
    ['/{companyCode}/sso/authServer', { companyCode }],
    {
      ...options,
      enabled: false,
      select: (res: any) => prop('data', res),
    }
  )

  return { data, isLoading, refetch }
}

export const useVerifySSOLogin = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (parmas: Record<string, any>) =>
      request(`/{companyCode}/sso/verifyLogin?${toQueryString(parmas)}`, {
        method: 'POST',
        body: { companyCode },
        displayMessage: false,
      }),
    options
  )
}
