const Dashboard = ({ ...props }) => {
  return (
    <i className="icon-dashboard" {...props}>
      <svg
        className="dashboard"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20">
        <path
          d="M10 1c-4.962 0-9 4.038-9 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9zm0 1.5c4.151 0 7.5 3.349 7.5 7.5s-3.349 7.5-7.5 7.5-7.5-3.349-7.5-7.5S5.849 2.5 10 2.5zm0 .75a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5zM5.758 5.008a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5zm8.522.799a.812.812 0 0 0-1.141-.004l-2.764 2.744a1.5 1.5 0 1 0 1.078 1.078l2.764-2.764a.786.786 0 0 0-.013-1.129l.076.075zM4 9.25a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5zm12 0a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5zM5.758 13.492a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5zm8.484 0a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5z"
          fill="#878787"
          fillRule="nonzero"
        />
      </svg>
    </i>
  )
}

export default Dashboard
