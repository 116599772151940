import styled from 'styled-components'

const ProgressLoading = ({ topCss }: { topCss?: string | number }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: '#9090905b',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
      }}>
      <StyleLoading top={topCss} />
    </div>
  )
}

const StyleLoading = styled.div.attrs((props: any) => ({
  top: props.top,
}))`
  position: absolute;
  top: ${p => p.top};
  width: calc(100px / cos(45deg));
  height: 8px;
  background: repeating-linear-gradient(
      -45deg,
      ${p => p.theme.loading.color} 0 15px,
      #0000 0 20px
    )
    right/200% 100%;
  animation: l3 2s infinite linear;

  @keyframes l3 {
    100% {
      background-position: left;
    }
  }
`

export default ProgressLoading
