import useComapnyCode from '@/hooks/useComapnyCode'
import { indexBy, prop, pathOr } from 'ramda'
import { useQuery, UseQueryOptions } from 'react-query'
import React from 'react'
import { IOrganization } from './organization.type'
import request, {
  convertData,
  Mutation,
  MutationOptions,
  useMakeMutation,
} from '@/services'

export const useOrganizationPaginationList = (
  query?: any,
  options?: UseQueryOptions<IOrganization>
) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery(
    ['/{companyCode}/organization/list', { companyCode, ...query }],
    options
  )

  const dataSource = React.useMemo(
    () =>
      convertData<IOrganization>(
        {
          field: ['data'],
          converter: x => x,
        },
        data
      ),
    [data]
  )

  const byId: any = React.useMemo(() => {
    return indexBy(prop('id'), pathOr([], ['data'], data))
  }, [data])

  return { dataSource, isLoading, byId, refetch }
}

export const useOrganizationById = (
  variables: { id?: number },
  options?: UseQueryOptions<IOrganization>
) => {
  const companyCode = useComapnyCode()

  return useQuery<IOrganization>(
    ['/{companyCode}/organization/{id}', { ...variables, companyCode }],
    {
      enabled: !!variables.id && !!companyCode,
      select: (res: any) => prop('data', res),
      ...options,
    }
  )
}

export const useAddOrganization = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/organization', {
        method: 'POST',
        body: { ...body, companyCode },
      }),
    options
  )
}

export const useEditOrganization = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/organization/{id}', {
        method: 'PUT',
        body: { ...body, companyCode },
      }),
    options
  )
}
