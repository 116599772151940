import { Func, FuncDefList, FuncSetting } from './types'
import i18n from 'i18next'
import { isNil } from '../utils/webHelper'

export const acts: {
  CREATE: object
  READ: object
  UPDATE: object
  DELETE: object
} = {
  CREATE: { value: 'add', name: i18n.t('common:add') },
  READ: { value: 'view', name: i18n.t('common:view') },
  UPDATE: { value: 'edit', name: i18n.t('common:modify') },
  DELETE: { value: 'delete', name: i18n.t('common:Delete') },
}

export const convertToFuncSetting = (def: FuncDefList) => {
  return {
    id: def.code,
    title: def.subName,
    name: def.code,
    action: [
      def.viewAvailable ? { ...acts.READ, id: def.code } : null,
      def.editAvailable ? { ...acts.UPDATE, id: def.code } : null,
      def.addAvailable ? { ...acts.CREATE, id: def.code } : null,
      def.deleteAvailable ? { ...acts.DELETE, id: def.code } : null,
    ],
  } as FuncSetting
}

export const setFunc = (auth: FuncDefList, permission: Func) => {
  if (permission) {
    const child = convertToFuncSetting(auth)

    return {
      ...permission,
      children: [...permission.children, child],
    }
  }

  return {
    id: auth.code,
    title: auth.name,
    children: [convertToFuncSetting(auth)],
  } as Func
}

export const convertAuthToFuncSetting = (x: any) => {
  return {
    id: x.id,
    title: x.subName,
    name: x.code,
    action: [
      isNil(x.authView) ? null : { ...acts.READ, auth: x.authView, id: x.code },
      isNil(x.authEdit)
        ? null
        : { ...acts.UPDATE, auth: x.authEdit, id: x.code },
      isNil(x.authAdd) ? null : { ...acts.CREATE, auth: x.authAdd, id: x.code },
      isNil(x.authDelete)
        ? null
        : { ...acts.DELETE, auth: x.authDelete, id: x.code },
    ],
  }
}
