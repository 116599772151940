import { SVGProps } from 'react'

const MapHomeSVG = (props: SVGProps<any>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <g fill="#0074A8" stroke="#0074A8" strokeWidth="1.067">
          <g>
            <g>
              <g>
                <path
                  d="M8 1.333l-.436.361-6.23 6.904v5.116c0 .526.426.953.952.953h2.857c.526 0 .952-.427.952-.953v-3.81c0-.525.427-.952.953-.952h1.904c.526 0 .953.427.953.953v3.81c0 .525.426.952.952.952h2.857c.526 0 .953-.427.953-.953V8.598l-6.23-6.904L8 1.334z"
                  transform="translate(-200.000000, -184.000000) translate(184.000000, 84.000000) translate(0.000000, 88.000000) translate(16.000000, 12.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MapHomeSVG
