import { Spin } from 'antd'
import styled from 'styled-components'

type Props = {
  loading: boolean
  children?: React.ReactNode
}

const BlockLoading = ({ loading, ...props }: Props) => {
  return (
    <StyleDiv className="relative">
      <div className="absolute" style={{ top: 20, left: '50%' }}>
        <Spin spinning={loading} />
      </div>
      <div className="relative"> {props.children}</div>
    </StyleDiv>
  )
}

const StyleDiv = styled.div`
  position: relative;
  //background: rgba(0, 0, 0, 0.05) !important;
  width: 100%;
  height: 100%;
  z-index: 99999;
`

export default BlockLoading
