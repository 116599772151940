import React from 'react'
import {
  path,
  map,
  join,
  split,
  ifElse,
  not,
  isNil,
  length,
  pathOr,
  always,
} from 'ramda'
import { useTranslation } from 'react-i18next'
import * as Icons from '@icons'
import { useAuth, UserPermission } from '@/auth'
import { useOrganizationDisplayLabel } from '@/hooks/useRuntimeProps'
const { compose } = require('ramda')

export type RouteItem = {
  id: number
  title: string
  path: string
  component: typeof React.Component
  icon?: React.FunctionComponent
  disabled?: boolean
  param?: string
  children?: RouteItem[]
  hide?: RouteItem[]
  creatable?: boolean
  updatable?: boolean
  deletable?: boolean
  readable?: boolean
  isSubMenu: boolean
  code: string
  mainKey: string
  subKey: string
  parentName?: string
  url: string
  name?: string
  isSubMenuIcon?: boolean // 顯示 icon 判斷
}

export interface Route extends RouteItem {
  children: Array<RouteItem>
  hide: Array<RouteItem>
  disabled: boolean
}

let setting = {
  creatable: true,
  deletable: true,
  isSubMenu: true,
  readable: true,
  updatable: true,
}

export function usePrepareRoutes() {
  const { user } = useAuth()
  const { t } = useTranslation()

  const organizationDisplayLabel = useOrganizationDisplayLabel()

  let _user = user as any

  return (routes: { [key: string]: any }) => {
    // const __getHide = (path: any, component: any) => {
    //   const hide = pathOr({}, ['hideList', path], _user)
    //   return isEmpty(hide) ? [] : [{ ...hide, path, component }]
    // }

    const __getComponent = (p: any) => {
      return path([
        compose(
          join(''),
          map((s: string) => s.charAt(0).toUpperCase() + s.slice(1)),
          split('-'),
          ifElse(
            v => length(v) > 3,
            v => pathOr('', [length(v) - 1])(v),
            pathOr('', [1])
          ),
          split('/')
        )(p),
      ])(routes)
    }

    const funcList = compose(
      map((x: UserPermission) => ({
        ...x,
        title: x.isSubMenu
          ? t(`menu:${x.mainKey}`, { organizationDisplayLabel })
          : t(`menu:${x.code}`, { organizationDisplayLabel }),
        path: x.url,
        icon: ifElse(
          compose(not, isNil, path([x.icon])),
          () => (Icons as any)[x.icon],
          always(null)
        )(Icons),
        ...(x.isSubMenu
          ? {}
          : {
              component: __getComponent(pathOr('', ['url'], x)),
              // hide: __getHide(
              //   pathOr('', ['url'], x),
              //   __getComponent(pathOr('', ['url'], x))
              // ),
            }),
        children: compose(
          map((c: UserPermission) => {
            const component = __getComponent(pathOr('', ['url'], c))
            const name = c.url.split('/').pop()

            return {
              ...c,
              id: c.code,
              title: t(`menu:${name}`, { organizationDisplayLabel }),
              path: c.url,
              component,
              // hide: __getHide(c.url, component),
            }
          }),
          pathOr([], ['children'])
        )(x),
      })),
      pathOr([], ['funcList'])
    )(_user)
    return funcList
  }
}

export default usePrepareRoutes
