import { SVGProps } from 'react'

const Database = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M17.5 5.83333C17.5 7.675 14.1417 9.16667 10 9.16667C5.85833 9.16667 2.5 7.675 2.5 5.83333M17.5 5.83333C17.5 3.99167 14.1417 2.5 10 2.5C5.85833 2.5 2.5 3.99167 2.5 5.83333M17.5 5.83333V10M2.5 5.83333V10M17.5 10C17.5 11.8417 14.1417 13.3333 10 13.3333C5.85833 13.3333 2.5 11.8417 2.5 10M17.5 10V14.1667C17.5 16.0083 14.1417 17.5 10 17.5C5.85833 17.5 2.5 16.0083 2.5 14.1667V10"
      stroke="#878787"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Database
