import { SVGProps } from 'react'

const Book = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M17.753 7.62649C17.2561 6.52828 16.55 5.55902 15.6542 4.74548C15.3205 4.44253 14.8044 4.46734 14.5014 4.80098C14.1985 5.13462 14.2233 5.65076 14.5569 5.95371C15.2866 6.6161 15.8615 7.40515 16.2659 8.299C16.6841 9.22354 16.8963 10.2114 16.8963 11.2352C16.8963 13.1401 16.1546 14.931 14.8073 16.2784C13.46 17.6257 11.6694 18.3674 9.76416 18.3674C7.85893 18.3674 6.0683 17.6257 4.721 16.2784C3.37402 14.9314 2.63198 13.1404 2.63198 11.2352C2.63198 9.32997 3.37369 7.53933 4.721 6.19203C5.63672 5.27631 6.75746 4.64069 7.97972 4.32729C7.71072 4.64787 7.72672 5.12679 8.02837 5.42811C8.18768 5.58742 8.39661 5.66708 8.60555 5.66708C8.81448 5.66708 9.02342 5.58742 9.18273 5.42811L10.7004 3.9104C11.0191 3.59177 11.0191 3.07498 10.7004 2.75603L9.18273 1.23897C8.86411 0.920344 8.34732 0.920344 8.02837 1.23897C7.70941 1.55759 7.70974 2.07438 8.02837 2.39333L8.23828 2.60325C6.47768 2.91045 4.8568 3.7488 3.56696 5.03864C1.91148 6.6938 1 8.89479 1 11.2358C1 13.5769 1.91148 15.7779 3.56696 17.433C5.22244 19.0885 7.42311 20 9.76416 20C12.1052 20 14.3062 19.0885 15.9614 17.433C17.6165 15.7776 18.5283 13.5769 18.5283 11.2358C18.5283 9.97832 18.2675 8.76421 17.753 7.62682V7.62649ZM10.9074 5.61158L5.57338 11.5796C5.41636 11.7552 5.54107 12.034 5.77677 12.034H8.76062L8.1472 16.5885C8.11129 16.8549 8.44167 17.007 8.62089 16.8066L13.9549 10.8385C14.112 10.6629 13.9872 10.3841 13.7515 10.3841H10.7677L11.3811 5.82966C11.417 5.56326 11.0866 5.41113 10.9074 5.61158Z"
      fill="#878787"
    />
  </svg>
)

export default Book
