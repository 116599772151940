// mappinng mainKey 顯示 icon
export const menuIcons: any = {
  ACT_TYPE1: 'IconCate1',
  ACT_TYPE2: 'IconCate2',
  ACT_TYPE3: 'IconCate3',
  ACT_TYPE4: 'IconCate4',
  ACT_TYPE5: 'IconCate5',
  ACT_TYPE6: 'IconCate6',
  data_maintain: 'IconMaintain',
  history: 'IconHistory',
  significance_assessment: 'IconReport',
  admin_significance_assessment: 'IconReport',
  inventory_making: 'IconReport',
  admin_inventory_making: 'IconReport',
  admin_reportMgm: 'IconStatic',
  reportMgm: 'IconStatic',
  systemMgm: 'IconSystem',
  system_system_management: 'IconSystem',
  system_company_list: 'IconCompanyList',
  system_company_management: 'IconCompany',
  green_electricity_certificate: 'IconGreenPower',
  admin_green_electricity_certificate: 'IconGreenPower',
  admin_warning_setting: 'IconAlert',
  warning_setting: 'IconAlert',
  coefficient_setting: 'IconDatabase',
  system_member_management: 'IconUser',
  userMgm: 'IconUser',
  admin_act_review: 'IconApproval',
  act_review: 'IconApproval',
  admin_review_setting: 'IconApproval',
  review_setting: 'IconApproval',
  admin_carbon_reduction_target: 'IconSystem',
  carbon_reduction_target: 'IconSystem',
}
