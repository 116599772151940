import { QueryObserverResult, RefetchOptions } from 'react-query'

export { default } from './Modal'

export interface ModalProps<T = any> {
  onOk?: (values: T) => any
  onCancel: (evt: React.MouseEvent<HTMLElement>) => void
  onConfirmDeletion?: (evt: React.MouseEvent<HTMLElement>) => any
  refetch?: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<any, any>>
  visible?: boolean
  loading?: boolean
  loadingData?: boolean
  item?: T | null
}
