import { SVGProps } from 'react'

const Calc = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M20.32 0H3.68C2.533.003 1.603.897 1.6 2v20c.003 1.103.933 1.997 2.08 2h16.64c1.147-.003 2.077-.897 2.08-2V2c-.003-1.103-.933-1.997-2.08-2zM7.84 20c-.002.552-.466.998-1.04 1H4.72c-.574-.002-1.038-.448-1.04-1v-.496c.002-.552.466-.998 1.04-1H6.8c.574.002 1.038.448 1.04 1V20zm0-4c-.002.552-.466.998-1.04 1H4.72c-.574-.002-1.038-.448-1.04-1v-.496c.002-.552.466-.998 1.04-1H6.8c.574.002 1.038.448 1.04 1V16zm0-4c-.002.552-.466.998-1.04 1H4.72c-.574-.002-1.038-.448-1.04-1v-.496c.002-.552.466-.998 1.04-1H6.8c.574.002 1.038.448 1.04 1V12zm6.24 8c-.002.552-.466.998-1.04 1h-2.08c-.574-.002-1.038-.448-1.04-1v-.496c.002-.552.466-.998 1.04-1h2.08c.574.002 1.038.448 1.04 1v.5V20zm0-4c-.002.552-.466.998-1.04 1h-2.08c-.574-.002-1.038-.448-1.04-1v-.496c.002-.552.466-.998 1.04-1h2.08c.574.002 1.038.448 1.04 1v.5V16zm0-4c-.002.552-.466.998-1.04 1h-2.08c-.574-.002-1.038-.448-1.04-1v-.496c.002-.552.466-.998 1.04-1h2.08c.574.002 1.038.448 1.04 1v.5V12zm6.24 8c-.002.552-.466.998-1.04 1H17.2c-.574-.002-1.038-.448-1.04-1v-.496c.002-.552.466-.998 1.04-1h2.08c.574.002 1.038.448 1.04 1v.5V20zm0-4c-.002.552-.466.998-1.04 1H17.2c-.574-.002-1.038-.448-1.04-1v-.496c.002-.552.466-.998 1.04-1h2.08c.574.002 1.038.448 1.04 1v.5V16zm0-4c-.002.552-.466.998-1.04 1H17.2c-.574-.002-1.038-.448-1.04-1v-.496c.002-.552.466-.998 1.04-1h2.08c.574.002 1.038.448 1.04 1v.5V12zm0-4.5c-.002.552-.466.998-1.04 1H4.72c-.572-.002-1.036-.446-1.04-.996v-4c.002-.552.466-.998 1.04-1h14.56c.574.002 1.038.448 1.04 1V7.5z"
        fill="#44596C"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default Calc
