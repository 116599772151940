import React from 'react'
import type { SelectProps } from 'antd'
import { Select } from '@components/ui/Select'
import { useSpecList, Spec } from '@/coefficient'

type Props = {
  title?: string | React.ReactNode
  className?: string
  onChange?: (val: any) => void
} & SelectProps<any>

const UsageSpecSelect = ({ title, onChange, ...props }: Props) => {
  const [value, setValue] = React.useState<number>()
  const { loading, dataSource, defaultSpecSeq } = useSpecList()

  React.useEffect(() => {
    if (!defaultSpecSeq) {
      return
    }

    setValue(defaultSpecSeq)
    onChange && onChange(defaultSpecSeq)
  }, [defaultSpecSeq]) //eslint-disable-line

  const handleChange = (id: any) => {
    setValue(id)
    onChange && onChange(id)
  }

  return (
    <>
      {title}
      <Select
        className="w-190 mr-16"
        loading={loading}
        value={value}
        onSelect={handleChange}
        {...props}>
        {dataSource.map((i: Spec) => (
          <Select.Option key={i.id} value={i.id}>
            {i.name}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default UsageSpecSelect
