import React from 'react'
import Loading from './Loading'

const ScreenLoading = React.memo(() => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      background: '#9090905b',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
    }}>
    <Loading />
  </div>
))

export default ScreenLoading
