import { SVGProps } from 'react'

const Cate4 = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M0.833984 9.16667V17.5H5.83398V13.3333H7.50065V17.5H12.5007V9.16667L6.66732 5L0.833984 9.16667ZM10.834 15.8333H9.16732V11.6667H4.16732V15.8333H2.50065V10.025L6.66732 7.05L10.834 10.025V15.8333ZM14.1673 5.83333H15.834V7.5H14.1673V5.83333ZM14.1673 9.16667H15.834V10.8333H14.1673V9.16667ZM14.1673 12.5H15.834V14.1667H14.1673V12.5Z"
      fill="#878787"
    />
    <path
      d="M8.33398 2.5V4.14167L10.0007 5.33333V4.16667H17.5007V15.8333H14.1673V17.5H19.1673V2.5H8.33398Z"
      fill="#878787"
    />
  </svg>
)

export default Cate4
