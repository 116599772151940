import React from 'react'
import { useQuery as useReactQuery } from 'react-query'
import type { UseQueryOptions, QueryKey, UseQueryResult } from 'react-query'
import { always, ifElse, is, head, last, identity } from 'ramda'

interface QueryOptions<T = any> extends UseQueryOptions<T> {
  lazy?: boolean
}

export type QueryFunction<TData = any> = (
  queryKey: QueryKey,
  options?: QueryOptions<TData>
) => UseQueryResult<TData>

export function useLazyQuery(
  queryKey: QueryKey,
  { enabled = false, ...options }: UseQueryOptions = {}
) {
  const [_enabled, setEnabled] = React.useState(enabled)
  const [variables, setVariables] = React.useState(() =>
    ifElse(is(Array), last, always(null))(queryKey)
  )

  let _queryKey = [ifElse(is(Array), head, identity)(queryKey), variables]

  const { refetch, ...rest } = useReactQuery(_queryKey, {
    ...options,
    cacheTime: 0,
    enabled: _enabled,
  })

  const _refetch = (variables: any) => {
    // trigger refetch whenever
    setVariables((prev: any) => ({ ...prev, ...variables, _time: +new Date() }))
    setEnabled(true)
  }

  const remove = () => {
    rest.remove()
    setEnabled(false)
  }

  return {
    ...rest,
    remove,
    refetch: _refetch,
  }
}

export function useSimpleLazyQuery(
  queryKey: QueryKey,
  { enabled = false, ...options }: UseQueryOptions = {}
) {
  const [_enabled, setEnabled] = React.useState(enabled)
  const [variables, setVariables] = React.useState(() =>
    ifElse(is(Array), last, always(null))(queryKey)
  )

  let _queryKey = [ifElse(is(Array), head, identity)(queryKey), variables]

  const { refetch, ...rest } = useReactQuery(_queryKey, {
    ...options,
    cacheTime: 0,
    enabled: _enabled,
  })

  const _refetch = (variables: any) => {
    setVariables((prev: any) => ({ ...prev, ...variables }))
    setEnabled(true)
  }

  const remove = () => {
    rest.remove()
    setEnabled(false)
  }

  return {
    ...rest,
    remove,
    refetch: _refetch,
  }
}

export default useLazyQuery
