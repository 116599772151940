import { SVGProps } from 'react'

const Cate5 = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11.7691 5.81681C11.6737 5.54672 11.497 5.31287 11.2631 5.14747C11.0293 4.98206 10.7499 4.89325 10.4634 4.89327H9.39314C9.08873 4.89413 8.79533 5.00725 8.56913 5.21098C8.34293 5.41471 8.19984 5.69472 8.16726 5.99739C8.13468 6.30005 8.2149 6.6041 8.39255 6.8513C8.57021 7.09851 8.83281 7.27148 9.13007 7.33711L10.7611 7.69295C11.0963 7.76442 11.3931 7.95768 11.5941 8.23533C11.795 8.51298 11.8858 8.8553 11.849 9.19605C11.8121 9.5368 11.6502 9.85177 11.3945 10.08C11.1388 10.3083 10.8076 10.4336 10.4648 10.4317H9.54268C9.25625 10.4317 8.97686 10.3429 8.74302 10.1775C8.50917 10.0121 8.33238 9.77825 8.237 9.50817M10.0024 4.89327V3.50867M10.0024 11.8163V10.4317"
      stroke="#878787"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.23286 18.9998H12.7713M16.2328 7.27352C16.2405 6.16135 15.9503 5.0674 15.3925 4.10523C14.8346 3.14306 14.0294 2.34775 13.0603 1.80187C12.0913 1.25599 10.9939 0.979437 9.88187 1.00092C8.76989 1.0224 7.68392 1.34113 6.73671 1.92403C5.7895 2.50693 5.01558 3.33275 4.49529 4.31574C3.975 5.29874 3.72732 6.40308 3.77794 7.51412C3.82857 8.62516 4.17567 9.7024 4.7832 10.634C5.39074 11.5656 6.23656 12.3176 7.23286 12.8119V14.8889C7.23286 15.0725 7.30579 15.2486 7.43563 15.3784C7.56546 15.5082 7.74155 15.5812 7.92516 15.5812H12.079C12.2626 15.5812 12.4387 15.5082 12.5685 15.3784C12.6983 15.2486 12.7713 15.0725 12.7713 14.8889V12.8119C13.8063 12.302 14.6785 11.5136 15.29 10.5352C15.9015 9.55681 16.228 8.42727 16.2328 7.27352Z"
      stroke="#878787"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Cate5
