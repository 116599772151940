import { SVGProps } from 'react'

const Approval = ({ className, ...props }: SVGProps<any>) => (
  <svg
    className={`style-icon-approval ${className}`}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 2.5H4.16667C3.72464 2.5 3.30072 2.67559 2.98816 2.98816C2.67559 3.30072 2.5 3.72464 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V10"
      stroke="#878787"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3132 2.18744C15.6447 1.85592 16.0944 1.66968 16.5632 1.66968C17.0321 1.66968 17.4817 1.85592 17.8132 2.18744C18.1448 2.51897 18.331 2.9686 18.331 3.43744C18.331 3.90629 18.1448 4.35592 17.8132 4.68744L10.3024 12.1991C10.1045 12.3968 9.86007 12.5415 9.59156 12.6199L7.1974 13.3199C7.12569 13.3409 7.04968 13.3421 6.97732 13.3236C6.90496 13.305 6.83892 13.2674 6.7861 13.2146C6.73328 13.1618 6.69564 13.0957 6.6771 13.0234C6.65856 12.951 6.65981 12.875 6.68073 12.8033L7.38073 10.4091C7.4595 10.1408 7.60451 9.89666 7.8024 9.69911L15.3132 2.18744Z"
      stroke="#878787"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Approval
