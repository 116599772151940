import { SVGProps } from 'react'

const Cate3 = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.33333 2.5H3.33333C3.11232 2.5 2.90036 2.5878 2.74408 2.74408C2.5878 2.90036 2.5 3.11232 2.5 3.33333V8.33333C2.5 8.55435 2.5878 8.76631 2.74408 8.92259C2.90036 9.07887 3.11232 9.16667 3.33333 9.16667H8.33333C8.55435 9.16667 8.76631 9.07887 8.92259 8.92259C9.07887 8.76631 9.16667 8.55435 9.16667 8.33333V3.33333C9.16667 3.11232 9.07887 2.90036 8.92259 2.74408C8.76631 2.5878 8.55435 2.5 8.33333 2.5ZM7.5 7.5H4.16667V4.16667H7.5V7.5ZM16.6667 10.8333H11.6667C11.4457 10.8333 11.2337 10.9211 11.0774 11.0774C10.9211 11.2337 10.8333 11.4457 10.8333 11.6667V16.6667C10.8333 16.8877 10.9211 17.0996 11.0774 17.2559C11.2337 17.4122 11.4457 17.5 11.6667 17.5H16.6667C16.8877 17.5 17.0996 17.4122 17.2559 17.2559C17.4122 17.0996 17.5 16.8877 17.5 16.6667V11.6667C17.5 11.4457 17.4122 11.2337 17.2559 11.0774C17.0996 10.9211 16.8877 10.8333 16.6667 10.8333ZM15.8333 15.8333H12.5V12.5H15.8333V15.8333ZM14.1667 2.5C12.3283 2.5 10.8333 3.995 10.8333 5.83333C10.8333 7.67167 12.3283 9.16667 14.1667 9.16667C16.005 9.16667 17.5 7.67167 17.5 5.83333C17.5 3.995 16.005 2.5 14.1667 2.5ZM14.1667 7.5C13.2475 7.5 12.5 6.7525 12.5 5.83333C12.5 4.91417 13.2475 4.16667 14.1667 4.16667C15.0858 4.16667 15.8333 4.91417 15.8333 5.83333C15.8333 6.7525 15.0858 7.5 14.1667 7.5ZM5.83333 10.8333C3.995 10.8333 2.5 12.3283 2.5 14.1667C2.5 16.005 3.995 17.5 5.83333 17.5C7.67167 17.5 9.16667 16.005 9.16667 14.1667C9.16667 12.3283 7.67167 10.8333 5.83333 10.8333ZM5.83333 15.8333C4.91417 15.8333 4.16667 15.0858 4.16667 14.1667C4.16667 13.2475 4.91417 12.5 5.83333 12.5C6.7525 12.5 7.5 13.2475 7.5 14.1667C7.5 15.0858 6.7525 15.8333 5.83333 15.8333Z"
      fill="#878787"
    />
  </svg>
)

export default Cate3
