import { SVGProps } from 'react'

const ImportSVG = (props: SVGProps<any>) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#000"
        strokeWidth="2.286"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.639 14.556 12.003 11l-3.636 3.556M12.002 11v8"
      />
      <path
        d="M19.63 16.68c1.824-.972 2.742-3.03 2.23-4.999-.513-1.968-2.325-3.346-4.403-3.348h-1.144c-.752-2.85-3.223-4.952-6.214-5.287-2.992-.334-5.884 1.168-7.276 3.779a6.987 6.987 0 0 0 1 7.995"
        stroke="#000"
        strokeWidth="2.286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="#000"
        strokeWidth="2.286"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.639 14.556 12.003 11l-3.636 3.556"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

export default ImportSVG
