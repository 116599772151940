import React from 'react'
import cx from 'classnames'
import { Form, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Rule } from 'rc-field-form/lib/interface'
import Input from '@components/Input'
import { rules as _rules } from '@/utils/form'
import FormItem from './TableFormItem'
import { useTranslation } from 'react-i18next'

export interface EditableRowProps {
  index: number
}

export const EditableTableContext = React.createContext<FormInstance<any> | null>(
  null
)

export const EditableRow: React.FC<EditableRowProps> = ({
  index,
  ...props
}) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableTableContext.Provider value={form}>
        <tr {...props} />
      </EditableTableContext.Provider>
    </Form>
  )
}

type InputRef =
  | ((instance: typeof Input | null) => void)
  | React.RefObject<typeof Input>
  | null
  | undefined

export type EditableCellProps = {
  editable?: boolean
  dataIndex: string
  title?: string
  record: {
    [key: string]: object
  }
  index: number
  handleSave: (values: object) => void
  children?: JSX.Element | JSX.Element[]
  className?: string
  Component?: any
  rules?: Rule[]
}

export function EditableCell({
  editable,
  dataIndex,
  title,
  record,
  index,
  handleSave,
  children,
  className,
  Component = Input,
  rules = [],
  ...restProps
}: EditableCellProps) {
  const [editing, setEditing] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement & InputRef>()
  const form = React.useContext(EditableTableContext)!
  const { t } = useTranslation()

  React.useEffect(() => {
    if (editing) {
      inputRef.current!.focus()
    }
  }, [editing, inputRef])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()

      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  const formItemStyle = React.useMemo(
    () => ({ margin: 0, marginTop: -10, marginBottom: -10 }),
    []
  )

  return (
    <td {...restProps}>
      {editable ? (
        <EditableTableContext.Consumer>
          {form =>
            editing ? (
              <FormItem
                style={formItemStyle}
                name={dataIndex}
                rules={[_rules.required, ...rules]}>
                <Component
                  ref={inputRef as any}
                  data-id={dataIndex}
                  className={cx(className)}
                  onPressEnter={save}
                  onBlur={save}
                />
              </FormItem>
            ) : (
              <Tooltip title={t('common:click to edit')}>
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={toggleEdit}>
                  {children}
                </div>
              </Tooltip>
            )
          }
        </EditableTableContext.Consumer>
      ) : (
        children
      )}
    </td>
  )
}
