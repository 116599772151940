import styled from 'styled-components'
import envStore from '@lib/env'

export const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  background-image: url(${envStore.publicUrl}/img/simpleMode/bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
`

export const Head = styled.h2`
  background-color: #fff;
  backdrop-filter: blur(12px);
  text-align: center;
  color: ${p => p.theme.blue};
  max-width: 700px;
  width: 90%;
  margin: 85px auto 0;
  font-size: 2rem;
  border-radius: 45px;
`

export const EntranceFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const RouteDiv = styled.div<{ imageUrl: string }>`
  max-width: 280px;
  min-width: 180px;
  aspect-ratio: 1.07;
  background-color: #fff;
  border-radius: 10px;
  background-image: url(${p => `${envStore.publicUrl}${p.imageUrl}`});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  > .title {
    width: 90%;
    min-height: 15%;
    margin: 0 auto;
    text-align: center;
    border: 1px solid ${p => p.theme.darkGrey};
    border-radius: 10px;
    position: absolute;
    top: 90%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #000;
    z-index: 1;

    &.zh_TW,
    &.zh_CN {
      height: 15%;
      font-size: 1.05rem;
    }
  }

  .frequency {
    position: absolute;
    bottom: 20px;
    color: ${p => p.theme.red};
    font-weight: 900;
  }
`
export const RoutesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`
