import { SVGProps } from 'react'

const CompanyList = ({ className, ...props }: SVGProps<any>) => (
  <svg
    className={`style-icon-company-list ${className}`}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3.22157C11 2.81979 10.7429 2.46308 10.3617 2.33601L9.32618 1.99078C9.08438 1.91007 8.82797 1.88265 8.57456 1.9104C8.32115 1.93815 8.07673 2.02043 7.85811 2.15156L4.42477 4.21156C4.16317 4.36852 3.94667 4.59056 3.79636 4.85604C3.64605 5.12152 3.56705 5.4214 3.56706 5.72648V16.8215H2.83372C2.58619 16.8215 2.34879 16.9198 2.17376 17.0948C1.99872 17.2699 1.90039 17.5073 1.90039 17.7548C1.90039 18.0023 1.99872 18.2397 2.17376 18.4148C2.34879 18.5898 2.58619 18.6881 2.83372 18.6881H17.8337C18.0813 18.6881 18.3187 18.5898 18.4937 18.4148C18.6687 18.2397 18.7671 18.0023 18.7671 17.7548C18.7671 17.5073 18.6687 17.2699 18.4937 17.0948C18.3187 16.9198 18.0813 16.8215 17.8337 16.8215H17.1004V12.9333C17.1004 12.4179 16.6825 12 16.1671 12C15.6516 12 15.2337 12.4179 15.2337 12.9333V16.8215H9.60039V4.05019L9.77149 4.10719C10.3759 4.30855 11 3.85865 11 3.22157ZM5.43372 5.78309V16.8215H7.73372V4.40309L5.43372 5.78309Z"
      fill="#878787"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1504 6.00002C13.1504 5.53058 13.5309 5.15002 14.0004 5.15002H18.0004C18.4698 5.15002 18.8504 5.53058 18.8504 6.00002C18.8504 6.46947 18.4698 6.85002 18.0004 6.85002H14.0004C13.5309 6.85002 13.1504 6.46947 13.1504 6.00002Z"
      fill="#878787"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1504 3.00002C12.1504 2.53058 12.5309 2.15002 13.0004 2.15002H18.0004C18.4698 2.15002 18.8504 2.53058 18.8504 3.00002C18.8504 3.46947 18.4698 3.85002 18.0004 3.85002H13.0004C12.5309 3.85002 12.1504 3.46947 12.1504 3.00002Z"
      fill="#878787"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1504 9.00002C13.1504 8.53058 13.5309 8.15002 14.0004 8.15002H18.0004C18.4698 8.15002 18.8504 8.53058 18.8504 9.00002C18.8504 9.46947 18.4698 9.85002 18.0004 9.85002H14.0004C13.5309 9.85002 13.1504 9.46947 13.1504 9.00002Z"
      fill="#878787"
    />
  </svg>
)

export default CompanyList
