import { not, prop } from 'ramda'
import packageInfo from './../desktop/package.json'

class RunTimeEnv {
  private _runTimeEnv: Record<string, any> | null = null
  private _isFetchedRunTimeEnv = false

  constructor() {
    fetch(
      `${
        process.env.REACT_APP_BASE_NAME || ''
      }/env/runtime.json?cache=${new Date().getTime()}`
    )
      .then(response => response.json())
      .then(envJson => (this._runTimeEnv = envJson))
      .finally(() => {
        this.emitRunTimeEnvChange()
        this._isFetchedRunTimeEnv = true
      })
  }

  private _runTimeEnvChangeListeners: (() => void)[] = []

  public addRunTimeEnvChangeListener(listener: () => void) {
    this._runTimeEnvChangeListeners.push(listener)
  }

  public removeRunTimeEnvChangeListener(listener: () => void) {
    const removeIndex = this._runTimeEnvChangeListeners.indexOf(listener)
    if (removeIndex > -1) {
      this._runTimeEnvChangeListeners.splice(removeIndex, 1)
    }
  }

  private emitRunTimeEnvChange() {
    this._runTimeEnvChangeListeners.forEach(l => l())
  }

  public get isFetchedRunTimeEnv() {
    return this._isFetchedRunTimeEnv
  }

  public getRuntimeEnvIfHas(
    key: string,
    defaultEnvValue: string | undefined,
    returnType?: 'boolean'
  ): any {
    let _result = defaultEnvValue
    if (this._runTimeEnv && prop(key, this._runTimeEnv)) {
      _result = prop(key, this._runTimeEnv)
    }
    // 透過 env 檔案 boolean 會是 'true' | 'false' 的字串
    if (returnType === 'boolean') {
      return _result === 'true'
    }
    return _result
  }
}

class EnvStore {
  pageSize = 10

  uploadFileUrlPrefix = process.env.REACT_APP_UPLOAD_FILE_URL_PREFIX

  localStoragePrefix = process.env.REACT_APP_LOCALSTORAGE_PREFIX || 'sctc'

  private _runTimeEnv = new RunTimeEnv()

  private _uploadMaxSize: string | null = null
  private _currentSystemlang: string = 'zh_TW'

  get runTimeEnv() {
    return this._runTimeEnv
  }

  get currentSystemLang(): string {
    return this._currentSystemlang
  }

  set currentSystemLang(lang: string) {
    this._currentSystemlang = lang
  }

  get uploadMaxSize(): string | null {
    return this._uploadMaxSize
  }

  set uploadMaxSize(maxSize: string | null) {
    let _maxSize = maxSize
    if (maxSize?.endsWith('M')) {
      _maxSize = maxSize?.replace('M', 'MB')
    }
    this._uploadMaxSize = _maxSize
  }

  get apiBaseUrl(): string {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_API_ROOT',
      process.env.REACT_APP_API_ROOT
    )
  }

  get importSettingMode(): 'BY_SYSTEM' | 'BY_COMPANY' {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_IMPORT_SETTING_MODE',
      process.env.REACT_APP_IMPORT_SETTING_MODE
    )
  }

  get baseQuantity(): '單位' | '數量' {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_BASE_QUANTITY',
      process.env.REACT_APP_BASE_QUANTITY
    )
  }

  get simpleModeSupported(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_SIMPLE_MODE_SUPPORTED',
      process.env.REACT_APP_SIMPLE_MODE_SUPPORTED,
      'boolean'
    )
  }

  get disabledFormAt(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_DISABLED_FORM_AT',
      process.env.REACT_APP_DISABLED_FORM_AT,
      'boolean'
    )
  }

  get disabledReCaptcha(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_DISABLED_RECAPTCHA',
      process.env.REACT_APP_DISABLED_RECAPTCHA,
      'boolean'
    )
  }

  get hiddenNoIndependDocuments(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_HIDDEN_NO_INDEPENDENT_DOCUMENTS',
      process.env.REACT_APP_HIDDEN_NO_INDEPENDENT_DOCUMENTS,
      'boolean'
    )
  }

  get disableExpoNumber(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_DISABLE_EXPO_NUMBER',
      process.env.REACT_APP_DISABLE_EXPO_NUMBER,
      'boolean'
    )
  }

  get hiddenOriginAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_HIDDEN_ORIGIN_AMOUNT',
      process.env.REACT_APP_HIDDEN_ORIGIN_AMOUNT,
      'boolean'
    )
  }

  get hiddenNTDAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_HIDDEN_NTD_AMOUNT',
      process.env.REACT_APP_HIDDEN_NTD_AMOUNT,
      'boolean'
    )
  }

  get hiddenFieldsForGoodsMgmt(): string {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_HIDDEN_FIELDS_FOR_GOODS_MGMT',
      process.env.REACT_APP_HIDDEN_FIELDS_FOR_GOODS_MGMT
    )
  }

  get hiddenFieldsForAssetsMgmt(): string {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_HIDDEN_FIELDS_FOR_ASSETS_MGMT',
      process.env.REACT_APP_HIDDEN_FIELDS_FOR_ASSETS_MGMT
    )
  }

  get disabledActTypeBatchDelete(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_DISABLED_ACTTYPE_BATCH_DELETE',
      process.env.REACT_APP_DISABLED_ACTTYPE_BATCH_DELETE,
      'boolean'
    )
  }

  get disabledActTypeCopy(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_DISABLED_ACTTYPE_COPY',
      process.env.REACT_APP_DISABLED_ACTTYPE_COPY,
      'boolean'
    )
  }

  // FixedCombustion 是否必填
  get requiredFixedCombustionNtdAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1FIXEDCOMBUSTION_NTDAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE1FIXEDCOMBUSTION_NTDAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredFixedCombustionOriginAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1FIXEDCOMBUSTION_ORIGINAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE1FIXEDCOMBUSTION_ORIGINAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredFixedCombustionEquiementInfo(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1FIXEDCOMBUSTION_EQUIEMENTINFO_REQUIRED',
      process.env.REACT_APP_ACTTYPE1FIXEDCOMBUSTION_EQUIEMENTINFO_REQUIRED,
      'boolean'
    )
  }

  get requiredFixedCombustionFirm(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1FIXEDCOMBUSTION_FIRM_REQUIRED',
      process.env.REACT_APP_ACTTYPE1FIXEDCOMBUSTION_FIRM_REQUIRED,
      'boolean'
    )
  }

  get requiredFixedCombustionReference(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1FIXEDCOMBUSTION_REFERENCE_REQUIRED',
      process.env.REACT_APP_ACTTYPE1FIXEDCOMBUSTION_REFERENCE_REQUIRED,
      'boolean'
    )
  }
  // MobileCombustion 是否必填
  get requiredMobileCombustionNtdAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1MOBILECOMBUSTION_NTDAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE1MOBILECOMBUSTION_NTDAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredMobileCombustionOriginAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1MOBILECOMBUSTION_ORIGINAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE1MOBILECOMBUSTION_ORIGINAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredMobileCombustionEquiementInfo(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1MOBILECOMBUSTION_EQUIEMENTINFO_REQUIRED',
      process.env.REACT_APP_ACTTYPE1MOBILECOMBUSTION_EQUIEMENTINFO_REQUIRED,
      'boolean'
    )
  }

  get requiredMobileCombustionFirm(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1MOBILECOMBUSTION_FIRM_REQUIRED',
      process.env.REACT_APP_ACTTYPE1MOBILECOMBUSTION_FIRM_REQUIRED,
      'boolean'
    )
  }

  get requiredMobileCombustionReference(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1MOBILECOMBUSTION_REFERENCE_REQUIRED',
      process.env.REACT_APP_ACTTYPE1MOBILECOMBUSTION_REFERENCE_REQUIRED,
      'boolean'
    )
  }
  // ProcessEmissio 是否必填
  get requiredProcessEmissionNtdAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1PROCESSEMISSION_NTDAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE1PROCESSEMISSION_NTDAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredProcessEmissionOriginAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1PROCESSEMISSION_ORIGINAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE1PROCESSEMISSION_ORIGINAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredProcessEmissionEquiementInfo(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1PROCESSEMISSION_EQUIEMENTINFO_REQUIRED',
      process.env.REACT_APP_ACTTYPE1PROCESSEMISSION_EQUIEMENTINFO_REQUIRED,
      'boolean'
    )
  }

  get requiredProcessEmissionFirm(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1PROCESSEMISSION_FIRM_REQUIRED',
      process.env.REACT_APP_ACTTYPE1PROCESSEMISSION_FIRM_REQUIRED,
      'boolean'
    )
  }

  get requiredProcessEmissionReference(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1PROCESSEMISSION_REFERENCE_REQUIRED',
      process.env.REACT_APP_ACTTYPE1PROCESSEMISSION_REFERENCE_REQUIRED,
      'boolean'
    )
  }
  // VaporizeEmission 是否必填
  get requiredVaporizeEmissionNtdAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1VAPORIZEEMISSION_NTDAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE1VAPORIZEEMISSION_NTDAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredVaporizeEmissionOriginAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1VAPORIZEEMISSION_ORIGINAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE1VAPORIZEEMISSION_ORIGINAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredVaporizeEmissionEquiementInfo(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1VAPORIZEEMISSION_EQUIEMENTINFO_REQUIRED',
      process.env.REACT_APP_ACTTYPE1VAPORIZEEMISSION_EQUIEMENTINFO_REQUIRED,
      'boolean'
    )
  }

  get requiredVaporizeEmissionFirm(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1VAPORIZEEMISSION_FIRM_REQUIRED',
      process.env.REACT_APP_ACTTYPE1VAPORIZEEMISSION_FIRM_REQUIRED,
      'boolean'
    )
  }

  get requiredVaporizeEmissionReference(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE1VAPORIZEEMISSION_REFERENCE_REQUIRED',
      process.env.REACT_APP_ACTTYPE1VAPORIZEEMISSION_REFERENCE_REQUIRED,
      'boolean'
    )
  }
  // InputPower 是否必填
  get requiredInputPowerNtdAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE2INPUTPOWER_NTDAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE2INPUTPOWER_NTDAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredInputPowerOriginAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE2INPUTPOWER_ORIGINAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE2INPUTPOWER_ORIGINAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredInputPowerEquiementInfo(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE2INPUTPOWER_EQUIEMENTINFO_REQUIRED',
      process.env.REACT_APP_ACTTYPE2INPUTPOWER_EQUIEMENTINFO_REQUIRED,
      'boolean'
    )
  }

  get requiredInputPowerFirm(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE2INPUTPOWER_FIRM_REQUIRED',
      process.env.REACT_APP_ACTTYPE2INPUTPOWER_FIRM_REQUIRED,
      'boolean'
    )
  }

  get requiredInputPowerReference(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE2INPUTPOWER_REFERENCE_REQUIRED',
      process.env.REACT_APP_ACTTYPE2INPUTPOWER_REFERENCE_REQUIRED,
      'boolean'
    )
  }
  // InputSteam 是否必填
  get requiredInputSteamNtdAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE2INPUTSTEAM_NTDAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE2INPUTSTEAM_NTDAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredInputSteamOriginAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE2INPUTSTEAM_ORIGINAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE2INPUTSTEAM_ORIGINAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredInputSteamEquiementInfo(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE2INPUTSTEAM_EQUIEMENTINFO_REQUIRED',
      process.env.REACT_APP_ACTTYPE2INPUTSTEAM_EQUIEMENTINFO_REQUIRED,
      'boolean'
    )
  }

  get requiredInputSteamFirm(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE2INPUTSTEAM_FIRM_REQUIRED',
      process.env.REACT_APP_ACTTYPE2INPUTSTEAM_FIRM_REQUIRED,
      'boolean'
    )
  }

  get requiredInputSteamReference(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE2INPUTSTEAM_REFERENCE_REQUIRED',
      process.env.REACT_APP_ACTTYPE2INPUTSTEAM_REFERENCE_REQUIRED,
      'boolean'
    )
  }
  // GoodsTrans 是否必填
  get requiredGoodsTransFirm(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE3GOODSTRANS_FIRM_REQUIRED',
      process.env.REACT_APP_ACTTYPE3GOODSTRANS_FIRM_REQUIRED,
      'boolean'
    )
  }

  get requiredGoodsTransNtdAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE3GOODSTRANS_NTDAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE3GOODSTRANS_NTDAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredGoodsTransOriginAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE3GOODSTRANS_ORIGINAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE3GOODSTRANS_ORIGINAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredGoodsTransAddressFrom(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE3GOODSTRANS_ADDRESSFROM_REQUIRED',
      process.env.REACT_APP_ACTTYPE3GOODSTRANS_ADDRESSFROM_REQUIRED,
      'boolean'
    )
  }

  get requiredGoodsTransAddressTo(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE3GOODSTRANS_ADDRESSTO_REQUIRED',
      process.env.REACT_APP_ACTTYPE3GOODSTRANS_ADDRESSTO_REQUIRED,
      'boolean'
    )
  }

  get requiredGoodsTransTransContent(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE3GOODSTRANS_TRANSCONTENT_REQUIRED',
      process.env.REACT_APP_ACTTYPE3GOODSTRANS_TRANSCONTENT_REQUIRED,
      'boolean'
    )
  }
  // EmployeeTravel
  get requiredEmployeeTravelAddressFrom(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE3EMPLOYEETRAVEL_ADDRESSFROM_REQUIRED',
      process.env.REACT_APP_ACTTYPE3EMPLOYEETRAVEL_ADDRESSFROM_REQUIRED,
      'boolean'
    )
  }

  get requiredEmployeeTravelAddressTo(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE3EMPLOYEETRAVEL_ADDRESSTO_REQUIRED',
      process.env.REACT_APP_ACTTYPE3EMPLOYEETRAVEL_ADDRESSTO_REQUIRED,
      'boolean'
    )
  }
  // PurchaseGoods
  get requiredPurchaseGoodsNtdAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE4PRUCHASEGOODS_NTDAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE4PRUCHASEGOODS_NTDAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredPurchaseGoodsOriginAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE4PRUCHASEGOODS_ORIGINAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE4PRUCHASEGOODS_ORIGINAMOUNT_REQUIRED,
      'boolean'
    )
  }
  // AssetMgmt
  get requiredAssetMgmtSN(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE4ASSETMANAGEMENT_SN_REQUIRED',
      process.env.REACT_APP_ACTTYPE4ASSETMANAGEMENT_SN_REQUIRED,
      'boolean'
    )
  }
  // Scrap
  get requiredScrapFirm(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE4SCRAP_FIRM_REQUIRED',
      process.env.REACT_APP_ACTTYPE4SCRAP_FIRM_REQUIRED,
      'boolean'
    )
  }
  get requiredScrapReference(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE4SCRAP_REFERENCE_REQUIRED',
      process.env.REACT_APP_ACTTYPE4SCRAP_REFERENCE_REQUIRED,
      'boolean'
    )
  }
  // WaterUsage
  get requiredWaterUsageFirm(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE4WATERUSAGE_FIRM_REQUIRED',
      process.env.REACT_APP_ACTTYPE4WATERUSAGE_FIRM_REQUIRED,
      'boolean'
    )
  }
  get requiredWaterUsageNtdAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE4WATERUSAGE_NTDAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE4WATERUSAGE_NTDAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredWaterUsageOriginAmount(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE4WATERUSAGE_ORIGINAMOUNT_REQUIRED',
      process.env.REACT_APP_ACTTYPE4WATERUSAGE_ORIGINAMOUNT_REQUIRED,
      'boolean'
    )
  }

  get requiredWaterUsageReference(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ACTTYPE4WATERUSAGE_REFERENCE_REQUIRED',
      process.env.REACT_APP_ACTTYPE4WATERUSAGE_REFERENCE_REQUIRED,
      'boolean'
    )
  }

  get requiredGoodInfoFirm(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_GOODSINFO_FIRM_REQUIRED',
      process.env.REACT_APP_GOODSINFO_FIRM_REQUIRED,
      'boolean'
    )
  }

  get requiredGoodInfoAnnounceYear(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_GOODSINFO_ANNOUNCEYEAR_REQUIRED',
      process.env.REACT_APP_GOODSINFO_ANNOUNCEYEAR_REQUIRED,
      'boolean'
    )
  }

  get requiredEmployeeCommuteAddressFrom(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_EMPLOYEECOMMUTE_ADDRESSFROM_REQUIRED',
      process.env.REACT_APP_EMPLOYEECOMMUTE_ADDRESSFROM_REQUIRED,
      'boolean'
    )
  }

  get requiredEmployeeCommuteAddressTo(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_EMPLOYEECOMMUTE_ADDRESSTO_REQUIRED',
      process.env.REACT_APP_EMPLOYEECOMMUTE_ADDRESSTO_REQUIRED,
      'boolean'
    )
  }

  get organizationDisplayLabel(): string {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_ORGANIZATION_DISPLAY_LABEL',
      process.env.REACT_APP_ORGANIZATION_DISPLAY_LABEL
    )
  }

  get isSSOEnable(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_SSO_ENABLE',
      process.env.REACT_APP_SSO_ENABLE,
      'boolean'
    )
  }

  get isGoogleTranslateEnable(): boolean {
    return process.env.REACT_APP_GOOGLE_TRANSLATE_ENABLE === 'true'
      ? true
      : false
  }

  get simpleModePowerInputRoleIds(): number[] {
    const roleIdsString: string | undefined =
      this._runTimeEnv.getRuntimeEnvIfHas(
        'REACT_APP_ROLE_ID_FOR_SIMPLE_MODE_POWER_INPUT',
        process.env.REACT_APP_ROLE_ID_FOR_SIMPLE_MODE_POWER_INPUT
      )
    return (roleIdsString || '')
      .split(',')
      .map(id => parseInt(id))
      .filter(id => not(isNaN(id)))
  }

  get fullNameLabelForBranch(): string {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_FULL_NAME_LABEL_FOR_UPSERT_BRANCH',
      process.env.REACT_APP_FULL_NAME_LABEL_FOR_UPSERT_BRANCH
    )
  }

  get shortNameLabelForBranch(): string {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_SHORT_NAME_LABEL_FOR_UPSERT_BRANCH',
      process.env.REACT_APP_SHORT_NAME_LABEL_FOR_UPSERT_BRANCH
    )
  }

  get shortNameLabelForBranchList(): string {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_SHORT_NAME_LABEL_FOR_BRANCH_LIST',
      process.env.REACT_APP_SHORT_NAME_LABEL_FOR_BRANCH_LIST
    )
  }

  get requiredFormText(): string {
    return process.env.REACT_APP_FORMTYPEID_FORMTEXT_REQUIRED || '4'
  }

  /**
   * 帳號申請資料檔案下載連結
   */
  get memberRequestFileURL(): string {
    return `${
      process.env.REACT_APP_BASE_NAME || ''
    }/download/member_request_list.docx`
  }

  /**
   * 帳號申請資料檔案名稱
   */
  get memberRequestFileName(): string {
    return '兆豐金控環境永續管理平台帳號暨權限申請單.docx'
  }

  get googleReCAPTCHAKey(): string {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_GOOGLE_RECAPTCHA_KEY',
      process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY
    )
  }

  get cspNonce(): string {
    return 'yjm6aQhtaGa+5+qeLtdeuw=='
  }

  get isEnvDev(): boolean {
    return process.env.NODE_ENV === 'development'
  }
  get appBaseName(): string {
    return process.env.REACT_APP_BASE_NAME || ''
  }
  get publicUrl(): string {
    return process.env.PUBLIC_URL || ''
  }
  get appUrl(): string {
    return `${window.location.protocol}//${window.location.host}${this.appBaseName}`
  }
  get canUseDOM(): boolean {
    return !!(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    )
  }
  get imageUrlPrefix(): string {
    return `${this.publicUrl}/img`
  }
  get mqttServer(): any {
    return {
      url:
        process.env.REACT_APP_MQTT_SERVER_URL ||
        'ws://broker.mqttdashboard.com:8000/mqtt',
      username: process.env.REACT_APP_MQTT_SERVER_USERNAME || '',
      password: process.env.REACT_APP_MQTT_SERVER_PASSWORD || '',
    }
  }
  get minimumClusterSize(): number {
    let minClusterSize = process.env.REACT_APP_MINIMUN_CLUSTER_SIZE
    return minClusterSize ? Number(minClusterSize) : 16
  }
  get defaultClusterSize(): number {
    return Number(process.env.REACT_APP_DEFAULT_CLUSTER_SIZE) || 2
  }
  get clientUrl(): string {
    return this.isEnvDev ? '/nid/auth' : ''
  }

  set setPageSize(pageSize: number | undefined) {
    this.pageSize = pageSize || 50
  }

  get appVersion(): string {
    return packageInfo.version || '0.0.0'
  }

  /**
   * 所有數值 設定小數點後N位, 四捨五入
   */
  get decimalNumber(): number {
    return (
      Number(
        this._runTimeEnv.getRuntimeEnvIfHas(
          'REACT_APP_DECIMAL_NUMBER',
          process.env.REACT_APP_DECIMAL_NUMBER
        )
      ) || 4
    )
  }

  get ignoreWarning(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_IGNORE_WARNING',
      process.env.REACT_APP_IGNORE_WARNING,
      'boolean'
    )
  }

  get disableSingleEntry(): boolean {
    return this._runTimeEnv.getRuntimeEnvIfHas(
      'REACT_APP_DISABLED_SINGLE_ENTRY',
      process.env.REACT_APP_DISABLED_SINGLE_ENTRY,
      'boolean'
    )
  }
}

export const envStore = new EnvStore()

export const endpoints = {
  apiBaseUrl: envStore.apiBaseUrl,
}

export const defaultPageSize = () => envStore.pageSize

export const imageUrlPrefix = envStore.imageUrlPrefix

export const mqttServer = envStore.mqttServer

export const publicUrl = envStore.publicUrl

export const clientUrl = envStore.clientUrl

export default envStore
