import React from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Form, Radio, Row, FormInstance } from 'antd'
import { range } from 'ramda'
import styled from 'styled-components'
import cx from 'classnames'
import Input from '@components/ui/Input'
import { getTotalRating } from '@/assessment'
import type { IAssessment } from '@/assessment'
import { ActionTypes } from '@lib/auth'
import useRouteAuth from '@/routes/useRouteAuth'
import { useTranslation } from 'react-i18next'

type ICategoryTable = {
  includeRating?: number
  category: IAssessment
  actType: number
  form: FormInstance
}

const CategoryTable = React.memo<ICategoryTable>(props => {
  const history = useHistory()
  const [, checkIsAuthenticated] = useRouteAuth()

  const { includeRating, category, actType, form } = props

  const [cat, setCat] = React.useState(category)

  const totalRating = React.useMemo(() => {
    return getTotalRating(cat.items)
  }, [cat])

  const isInclude = React.useMemo(
    () => includeRating && totalRating >= includeRating,
    [includeRating, totalRating]
  )

  React.useEffect(() => {
    form.setFieldsValue({ [actType]: category })
  }, [category, form, actType])

  const creatable = React.useMemo(
    () =>
      checkIsAuthenticated(
        ActionTypes.CREATABLE,
        `${history.location.pathname.replace('/categories', '')}`
      ),
    []
  )

  const renderRow = React.useCallback(() => {
    return range(0, 3).map(r => {
      return (
        <div className="table-row row" key={r}>
          {cat?.items.map((i, idx) => {
            const detail = i?.details[r]
            return (
              <div className="table-cell cell" key={i.id}>
                <Form.Item noStyle>
                  <Radio
                    disabled={!creatable}
                    checked={detail?.checked}
                    onClick={() =>
                      handleChange({
                        idx,
                        detaiIdx: r,
                        checked: !detail?.checked,
                      })
                    }>
                    {i ? detail?.description : null}
                  </Radio>
                </Form.Item>
              </div>
            )
          })}
        </div>
      )
    })
  }, [cat, creatable])

  const handleChange = React.useCallback(
    (v: any) => {
      const { idx, detaiIdx, checked } = v
      const details = cat.items[idx].details.map((i, index) =>
        index === detaiIdx ? { ...i, checked } : { ...i, checked: false }
      )

      const items = [...cat.items]
      items[idx].details = details

      const param = {
        ...cat,
        items,
      }

      form.setFieldValue([actType, 'items'], items)
      setCat(param)
    },
    [cat]
  )

  const handleNotApplicable = () => {
    const namePath = [actType, 'notApplicable']
    const checked = form.getFieldValue(namePath)
    form.setFieldValue(namePath, !checked)
  }

  const { t } = useTranslation()

  return (
    <div>
      <Form.Item hidden name={[actType, 'actType']} />
      <Form.Item hidden name={[actType, 'items']} />
      <Row justify="space-between">
        <Col>
          <label>{`${cat.sequence}.  ${cat.categoryName} - ${cat.actTypeColloquialDesc}`}</label>
        </Col>
        <Col className="flex items-end">
          {t('common:add up')}
          <Input
            className={cx('mx-15', 'w-60', {
              'text-blue': isInclude,
              'text-red': !isInclude,
            })}
            disabled
            value={totalRating}
          />
          {t('common:point')}
          <span
            className={cx('mx-15', {
              'text-blue': isInclude,
              'text-red': !isInclude,
            })}>
            ({isInclude ? t('common:include') : t('common:not include')})
          </span>
        </Col>
      </Row>
      <div className="px-15">
        <div style={{ overflow: 'auto', width: ' 100%' }}>
          <StyledTable>
            <div className="table-row row">
              {cat.items.map(x => (
                <div key={x.id} className="table-cell cell">
                  {x?.name}
                </div>
              ))}
            </div>
            {renderRow()}
          </StyledTable>
        </div>
        <Form.Item noStyle name={[actType, 'description']}>
          <Input placeholder={t('common:include desc')} />
        </Form.Item>
        <Row className="mt-15">
          <Col>
            <Form.Item
              noStyle
              name={[actType, 'notApplicable']}
              valuePropName="checked">
              <Radio disabled={!creatable} onClick={handleNotApplicable}>
                {t('common:not applicable')}
              </Radio>
            </Form.Item>
            <Form.Item noStyle name={[actType, 'notApplicableReason']}>
              <Input
                disabled={!creatable}
                placeholder={t('common:fill in the reason')}
                className="w-220 mx-15"
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  )
})

export default CategoryTable

const StyledTable = styled.div`
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e7e7e7;
  border-radius: 0px !important;
  margin: 10px 0px 20px 0px;
  padding: 10px;
  display: table;
  width: max-content;

  .cell {
    min-height: 48px;
    padding: 8px 16px;
    border-bottom: 1px dashed #b8b8b8;
    border-right: 1px dashed #b8b8b8;
    width: 255px;
    :last-child {
      border-right: none;
    }
  }

  .row:last-child {
    .cell {
      border-bottom: none;
    }
  }

  .ant-radio-disabled + span {
    color: #000;
  }

  .ant-radio.ant-radio-disabled .ant-radio-inner {
    border-color: #000;

    ::after {
      background-color: #000;
    }
  }
`
