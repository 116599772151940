import { SVGProps } from 'react'

const AddCircleSVG = (props: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c-.002-5.522-4.478-9.998-10-10zm0 18a8 8 0 1 1 8-8 8.01 8.01 0 0 1-8 8z"
        fill="#000"
        fillRule="nonzero"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

export default AddCircleSVG
