import { map, filter, join, keys } from 'ramda'
const { compose } = require('ramda')

export default function toQueryString(
  paramsObject: {
    [key: string]: any
  } = {}
) {
  if (!paramsObject) {
    return ''
  }

  return compose(
    join('&'),
    map((key: string) =>
      Array.isArray(paramsObject[key])
        ? // convert to key=val1,val2,val3 string
          `${key}=${paramsObject[key]
            .map((val: string | number) => `${encodeURIComponent(val)}`)
            .join(',')}`
        : // convert to key=val string
          `${key}=${encodeURIComponent(paramsObject[key])}`
    ),
    filter(
      (key: string) =>
        paramsObject[key] !== '' &&
        paramsObject[key] !== null &&
        typeof paramsObject[key] !== 'undefined'
    ),
    keys
  )(paramsObject)
}
