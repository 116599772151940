import { SVGProps } from 'react'

const AddSVG = (props: SVGProps<any>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.4 4.8H0v16.8A2.407 2.407 0 0 0 2.4 24h16.8v-2.4H2.4V4.8zM21.6 0H7.2a2.407 2.407 0 0 0-2.4 2.4v14.4a2.407 2.407 0 0 0 2.4 2.4h14.4a2.407 2.407 0 0 0 2.4-2.4V2.4A2.407 2.407 0 0 0 21.6 0zm-1.2 10.8h-4.8v4.8h-2.4v-4.8H8.4V8.4h4.8V3.6h2.4v4.8h4.8v2.4z"
        fill="#000"
        fillRule="nonzero"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)

export default AddSVG
