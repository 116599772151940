import Modal, { ModalProps } from '@components/ui/Modal'
import { useTranslation } from 'react-i18next'
import React from 'react'
import Button from '@components/ui/Button'
import styled from 'styled-components'

interface DeleteConfirmModalProps extends ModalProps {
  title?: string | React.ReactNode
  onOk: (v?: any) => void
}

export default function DeleteConfirmModal({
  title,
  onCancel,
  onOk,
  ...props
}: DeleteConfirmModalProps) {
  const { t } = useTranslation()

  return (
    <Modal
      visible
      width="400px"
      bodyStyle={{
        padding: '40px 30px',
      }}
      onCancel={onCancel}
      footer={
        <>
          <Button
            key="btn-cancel"
            color="danger"
            onClick={onOk}
            style={{ minWidth: 80, marginRight: 16 }}>
            {t('common:Delete')}
          </Button>
          <Button
            key="btn-ok"
            color="ghost"
            style={{ minWidth: 80 }}
            onClick={onCancel}>
            {t('common:Cancel')}
          </Button>
        </>
      }
      {...props}>
      <Text>{title}</Text>
    </Modal>
  )
}

const Text = styled.div`
  font-size: 15px;
  color: #2f3b40;
  text-align: center;
  font-weight: 500;
`
