import { QueryErrorResetBoundary } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ErrorBoundary } from './ErrorBoundary'
import ErrorFallback from './ErrorFallback'

interface Props {
  children?: React.ReactNode
}

export default function QueryErrorBoundary({ children }: Props) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary onReset={reset} fallbackRender={ErrorFallback}>
          <ReactQueryDevtools initialIsOpen={false} />
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}
