import { useTranslation } from 'react-i18next'
import Button, { Props } from './Button'

const PreviewButton = (props: Props) => {
  const { t } = useTranslation()
  return (
    <Button color="primary" {...props}>
      {t('common:preCalculate')}
    </Button>
  )
}

export default PreviewButton
