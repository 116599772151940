import { QueryClient, QueryFunctionContext } from 'react-query'
import { isNil, is, pathOr } from 'ramda'
import { format, request } from '@/services'
import toQueryString from '@/utils/toQueryString'
import { message, destroy } from '@/utils/message'
import { AuthContextType } from '@/auth'
import { persistQueryKeyStorage } from './usePersistQuery'
import i18n from 'i18next'

type Options = [string, { [key: string]: any }]

// Define a default query function that will receive the query key
async function defaultQueryFn({ queryKey }: QueryFunctionContext<Options>) {
  let _queryKey: any = queryKey

  if (is(String, _queryKey)) {
    _queryKey = [_queryKey]
  }

  const [url, { pageNum = 0, pageSize = 10, ..._params } = {}] = _queryKey

  const { fullUrl, params } = format(url, { pageNum, pageSize, ..._params })

  // const controller = new AbortController()
  // const signal = controller.signal

  // delete params['pageNum']
  // delete params['pageSize']
  delete params['current']
  delete params['size']

  const isQueryUrl = url.indexOf('?') > -1
  const promise = !isQueryUrl
    ? request(`${fullUrl}?${toQueryString(params)}`)
    : request(`${fullUrl}`)

  // promise.cancel = () => signal.abort()

  return promise
}

// async function defaultMutationFn() {}

const onError = ({ error, auth }: { error: any; auth: AuthContextType }) => {
  const { message: msg, status } = error
  const { logout, user } = auth
  const isTokenExpired = msg === 'Unauthorized' && status === 401
  const logoutIfNeed = isTokenExpired && user

  if (logoutIfNeed) {
    logout()
    message({
      content: i18n.t('common:Login expired,please login again'),
      maxCount: 1,
      type: 'error',
      top: 100,
      onClose: () => {
        destroy()
      },
    })
  }
}

type CreateQueryClientOptions = {
  /**
   * default is true
   */
  refetchPersistOnError: boolean
}

export let queryClient: QueryClient

function refetchPersistIfNeed(error: any) {
  const errorCode: number | null = pathOr(null, ['error', 'code'], error)
  if (isNil(errorCode)) {
    return
  }
  const queryKeys = persistQueryKeyStorage.getQueryKey(errorCode)
  queryKeys.forEach(async queryKey => {
    try {
      await queryClient.invalidateQueries(queryKey, {
        refetchInactive: true,
      })
    } catch (error) {
      console.error('invalidate queries error:', error)
    }
  })
}

export default function createQueryClient(
  auth: AuthContextType,
  options?: CreateQueryClientOptions
) {
  queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        onError: (error: any) => {
          onError({ auth, error })
          if (options?.refetchPersistOnError || true) {
            refetchPersistIfNeed(error)
          }
        },
      },
      queries: {
        onError: (error: any) => onError({ auth, error }),
        queryFn: defaultQueryFn,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: false,
        retry: false,
      },
    },
  })

  return queryClient
}
