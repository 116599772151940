const Camera = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#0074A8">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path d="M9 3.333c.368 0 .667.299.667.667v1.872l4.031-2.047c.328-.167.73-.036.896.293.048.093.073.197.073.302v7.827c0 .368-.299.667-.667.667-.105 0-.208-.025-.302-.072l-4.032-2.048v1.873c0 .334-.246.611-.567.66L9 13.332H2c-.368 0-.667-.298-.667-.666V4c0-.368.299-.667.667-.667z" transform="translate(-323 -790) translate(184 596) translate(0 58) translate(8) translate(0 128) translate(131 8)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Camera
