import { useMutation } from 'react-query'
import { UploadFile } from 'antd/lib/upload/interface'
import { RcFile } from 'antd/lib/upload'
import React from 'react'
import { isEmpty, pathOr } from 'ramda'
import envStore from '@/env'
import request, { Mutation, MutationOptions, useMakeMutation } from '@/services'
import localStorageUtil from '@lib/utils/localstorage'

export type UploadedFile = UploadFile & {
  file?: RcFile
}

export const useUploadImage = () =>
  useMutation(body =>
    request('/upload/image', {
      method: 'POST',
      body,
    })
  )

export const useDownloadFile = () => {
  const downloadWithPath = React.useCallback(
    ({ apiUrl, fileName }: { apiUrl: string; fileName?: string }) => {
      if (isEmpty(apiUrl)) {
        return
      }
      fetch(`${envStore.apiBaseUrl}${apiUrl}`, {
        headers: {
          Authorization: `Bearer ${localStorageUtil.get('token')}`,
        },
      })
        .then(res => res.blob())
        .then(fileBlob => {
          const url = window.URL.createObjectURL(fileBlob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            fileName || pathOr('unknow', [1], /\/([^/]*\.\w+)/.exec(apiUrl))
          )
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
          window.URL.revokeObjectURL(url)
        })
    },
    []
  )

  return {
    downloadWithPath,
  }
}

export const useImport = (
  url: string,
  options: MutationOptions = {}
): Mutation => {
  return useMakeMutation(
    (body: any) =>
      request(url, {
        method: 'POST',
        body,
        headers: {},
      }),
    options
  )
}
