const Light = ({ ...props }) => {
  return (
    <i className="icon-light" {...props}>
      <svg
        className="light"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20">
        <path
          fill="#878787"
          d="M9.796 1c4.85 0 8.797 3.947 8.797 8.796 0 4.85-3.947 8.797-8.797 8.797C4.947 18.593 1 14.646 1 9.796 1 4.947 4.947 1 9.796 1zm0 1.466c-4.057 0-7.33 3.273-7.33 7.33s3.273 7.33 7.33 7.33 7.33-3.273 7.33-7.33-3.273-7.33-7.33-7.33zM12.65 4.86c.286-.119.604.023.754.323l.57 1.155c.1.202.112.436.034.647-.075.2-.225.35-.413.407L9.837 8.512l-.08.013-.032.001c-.11 0-.216-.037-.308-.106-.14-.105-.237-.277-.268-.467l-.065-.322-1.157.569c-.104.052-.176.189-.174.344l.096 6.635c.003.193-.129.36-.307.36h-.615c-.178 0-.31-.167-.306-.36l.1-6.882c.003-.234.12-.447.304-.54l1.94-.996c.021-.011.044-.047.059-.106l.027-.087c.064-.168.181-.3.334-.361z"
        />
      </svg>
    </i>
  )
}

export default Light
