import React from 'react'
import { useLocation } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'

type ISpecSelectConfig = {
  showSpecSelect?: boolean
  selectedSpecId?: number
}
/**
 * 規範選項
 */
const specSelectConfigAtom = atom<ISpecSelectConfig | undefined>({
  key: 'SpecSelectState',
  default: undefined,
})

const useNavbarConfig = () => {
  const [specSelectState, setSpecSelectState] =
    useRecoilState(specSelectConfigAtom)

  const location = useLocation()

  const specSelectConfig = React.useMemo(() => {
    return {
      selectedSpecId: specSelectState?.selectedSpecId,
      isEnable: specSelectState?.showSpecSelect,
      setSpecId: (id: number) => {
        setSpecSelectState(prev => ({
          ...prev,
          selectedSpecId: id,
        }))
      },
    }
  }, [setSpecSelectState, specSelectState])

  React.useEffect(() => {
    // 當 location 變更時，設為預設值
    setSpecSelectState(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const enableSpecSelect = React.useCallback(() => {
    // 在切換頁面後立即 enable 時，因時間差問題會造成 NavBar 沒有抓到 isEnable 的變化
    // 導致係數選擇器沒有 rerender ，而沒有正確重設 value，因此將啟用行為放到 setTimeout
    setTimeout(
      () =>
        setSpecSelectState({
          showSpecSelect: true,
        }),
      0
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    specSelectConfig,
    enableSpecSelect,
  }
}

export default useNavbarConfig
