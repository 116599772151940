import { SVGProps } from 'react'

const Cate6 = ({ className, ...props }: SVGProps<any>) => (
  <svg
    className={`style-icon-cate6 ${className}`}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_640_4528)">
      <path
        d="M3.33398 6.96775C3.33398 4.46921 3.33398 3.21906 4.11058 2.44334C4.88629 1.66675 6.13645 1.66675 8.63498 1.66675H12.169C14.6675 1.66675 15.9177 1.66675 16.6934 2.44334C17.47 3.21906 17.47 4.46921 17.47 6.96775V14.0357C17.47 16.5343 17.47 17.7844 16.6934 18.5602C15.9177 19.3367 14.6675 19.3367 12.169 19.3367H8.63498C6.13645 19.3367 4.88629 19.3367 4.11058 18.5602C3.33398 17.7844 3.33398 16.5343 3.33398 14.0357V6.96775Z"
        stroke="#878787"
        strokeWidth="1.6"
      />
      <path
        d="M17.3799 14.0359H6.77787C5.95621 14.0359 5.54538 14.0359 5.20789 14.126C4.75846 14.2465 4.34868 14.4832 4.01974 14.8123C3.6908 15.1414 3.45429 15.5513 3.33398 16.0008"
        stroke="#878787"
        strokeWidth="1.6"
      />
      <path
        d="M6.86914 6.08435H13.9371M6.86914 9.1766H11.2866M17.0294 16.6864H6.86914"
        stroke="#878787"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_640_4528">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Cate6
