import React from 'react'

interface NodePathIconProps {
  last?: boolean
  className?: string
  isExpand?: boolean
  hasChildren?: boolean
}

const lineColor = '#707070'
const NodePathIcon = React.memo<NodePathIconProps>(
  (props: NodePathIconProps) => {
    const { className = 'hover:' } = props

    return (
      <svg
        width="15"
        height="15"
        style={{ minWidth: '15px', display: 'inline-block' }}
        className={className}>
        <line
          x1="2"
          y1="0"
          x2="2"
          y2="10.2"
          style={{
            fill: 'transparent',
            stroke: lineColor,
            strokeWidth: 1,
            shapeRendering: 'crispEdges',
          }}
        />
        <line
          x1="2"
          y1="10.2"
          x2="12.2"
          y2="10.2"
          style={{
            fill: 'transparent',
            stroke: lineColor,
            strokeWidth: 1,
            shapeRendering: 'crispEdges',
          }}
        />
      </svg>
    )
  }
)

export default NodePathIcon
