import { SVGProps } from 'react'

const Book = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M27.5 21.094V1.406A1.4 1.4 0 0 0 26.094 0H6.875A5.626 5.626 0 0 0 1.25 5.625v18.75A5.626 5.626 0 0 0 6.875 30h19.219a1.4 1.4 0 0 0 1.406-1.406v-.937c0-.426-.19-.83-.521-1.1a13.067 13.067 0 0 1 0-4.377 1.4 1.4 0 0 0 .521-1.086zM8.75 7.852c0-.194.158-.351.352-.352h12.421c.194 0 .351.158.352.352v1.171a.353.353 0 0 1-.352.352H9.102a.353.353 0 0 1-.352-.352V7.852zm0 3.75c0-.194.158-.351.352-.352h12.421c.194 0 .351.158.352.352v1.172a.353.353 0 0 1-.352.352H9.102a.353.353 0 0 1-.352-.352v-1.172zM23.598 26.25H6.875a1.875 1.875 0 1 1 0-3.75h16.723a21.094 21.094 0 0 0 0 3.75z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default Book
