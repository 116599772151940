import React from 'react'

export interface UseTitleOptions {
  restoreOnUnmount?: boolean
}

const DEFAULT_USE_TITLE_OPTIONS: UseTitleOptions = {
  restoreOnUnmount: false,
}

function useTitle(
  title: string,
  options: UseTitleOptions = DEFAULT_USE_TITLE_OPTIONS
) {
  const prevTitleRef = React.useRef(document.title)
  document.title = title
  React.useEffect(() => {
    if (options && options.restoreOnUnmount) {
      return () => {
        document.title = prevTitleRef.current // eslint-disable-line
      }
    } else {
      return
    }
  }, []) // eslint-disable-line
}

export default typeof document !== 'undefined'
  ? useTitle
  : (_title: string) => {}
