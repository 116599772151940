import { indexBy, path, pathOr, prop } from 'ramda'
import { useQuery } from 'react-query'
import { ICountry } from './options.type'
import React from 'react'

export const useCountries = (query?: any) => {
  const { data, isLoading, refetch } = useQuery<ICountry | undefined>(
    ['/options/countries'],
    { select: data => path(['data'], data) }
  )

  const byId: any = React.useMemo(() => {
    return indexBy(prop('code'), pathOr([], ['countryInfos'], data))
  }, [data])

  return { data, isLoading, refetch, byId }
}
