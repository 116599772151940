import { SVGProps } from 'react'

const CheckedSVG = (props: SVGProps<any>) => (
  <svg 
    width="24" 
    height="24" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g 
      fill="none" 
      fillRule="evenodd">
      <path 
        fill="#4A4A4A" 
        fillRule="nonzero"
        d="m13.305 11.998 5.972-5.968a.92.92 0 0 0 0-1.303.92.92 0 0 0-1.303 0L12 10.692 6.026 4.725a.92.92 0 0 0-1.303 0 .92.92 0 0 0 0 1.303l5.972 5.967-5.972 5.97a.92.92 0 0 0 .651 1.573c.237 0 .471-.09.652-.27L12 13.301l5.974 5.97c.18.18.415.269.652.269a.92.92 0 0 0 .652-1.573l-5.973-5.97z" />
    </g>
  </svg>

)

export default CheckedSVG
