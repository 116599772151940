import { SVGProps } from 'react'

const FolderWhite = (props: SVGProps<any>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M1 1h24v24H1z" />
      <path
        d="M10.267 3.25c.736 0 1.385.473 1.608 1.174l.586 1.826h10.851c.933 0 1.688.755 1.688 1.688v13.125c0 .932-.755 1.687-1.688 1.687H2.688A1.686 1.686 0 0 1 1 21.062V4.938c0-.932.755-1.687 1.688-1.687zm13.046 4.688H2.688v13.125h20.625V7.938zm-13.046-3h-7.58V6.25h8l-.42-1.313z"
        fill="#000"
        fillRule="nonzero"
      />
      <path
        d="M12.992 9.08c-.33 0-.6.105-.825.315-.224.21-.33.48-.33.81 0 .33.106.6.33.825.226.21.495.315.825.315.33 0 .6-.105.825-.315.226-.21.346-.495.346-.825 0-.33-.12-.6-.33-.81a1.192 1.192 0 0 0-.84-.315zm-.854 3.165V20h1.71v-7.755h-1.71z"
        fill="#000"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default FolderWhite
