import { approvalCompanyCode } from '@/constants'
import { history } from '@/routes'
import { pathOr } from 'ramda'
import { matchPath } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

type useComapnyCodeOption = {
  supportSystemAdminCode?: boolean
}

const useComapnyCode = (option?: useComapnyCodeOption) => {
  const _approvalCompanyCode = useRecoilValue(approvalCompanyCode)
  const pathResult = matchPath(history.location.pathname, {
    path: '/companies/:companyCode/(.)*',
  })

  return pathOr(
    option?.supportSystemAdminCode
      ? 'systemAdmin'
      : _approvalCompanyCode &&
        history.location.pathname.indexOf('admin-approval-management') > -1 // 如為簽核頁面, 帶入 companyCode
      ? (_approvalCompanyCode as any)
      : '',
    ['params', 'companyCode'],
    pathResult
  )
}

export default useComapnyCode
