import Button from '@components/ui/Button'
import React from 'react'
import styled from 'styled-components'
import { WarningOutlined } from '@ant-design/icons'
import Modal from '@components/ui/Modal'
import { useTranslation } from 'react-i18next'
import useComapnyCode from '@lib/hooks/useComapnyCode'

type IForgotPassowrdModalProp = {
  visible?: boolean
  onOk?: () => void
}

const ForgotPassowrdModal = React.memo<IForgotPassowrdModalProp>(props => {
  const { visible, onOk } = props

  const { t } = useTranslation()
  const companyCode = useComapnyCode()

  return (
    <ForgotPassowrdModalStyled
      visible={visible}
      footer={
        <div className="flex justify-center">
          <Button color="secondary" onClick={onOk}>
            {t('common:confirm')}
          </Button>
        </div>
      }
      centered
      closable={false}>
      <div className="flex justify-center items-center text-lg">
        <WarningOutlined
          style={{ color: '#f8a204', fontSize: '3rem', marginRight: '15px' }}
        />
        {companyCode
          ? t('common:please contact company manager')
          : t('common:please contact system admin')}
      </div>
    </ForgotPassowrdModalStyled>
  )
})

const ForgotPassowrdModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    background-color: #e7f3fd;
    padding: 60px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .ant-modal-footer {
    background-color: #e7f3fd;
  }
`

export default ForgotPassowrdModal
