import useAuth from '@lib/auth'
import useComapnyCode from '@lib/hooks/useComapnyCode'
import { path, pathOr, includes } from 'ramda'
import React from 'react'
import { useLocation } from 'react-router-dom'

export function usePagePermission() {
  const location = useLocation()
  const { user } = useAuth()

  const urlCompanyCode = useComapnyCode()

  /**
   * 指定公司是否有讀取所有活動的權限
   */
  const hasPagePermission = React.useMemo(() => {
    if (!urlCompanyCode) {
      return true
    }

    const originalUrl = location.pathname.replace(
      urlCompanyCode,
      ':companyCode'
    )
    const mainKey = path(['authByUrl', originalUrl, 'mainKey'], user) as any
    const actType = mainKey?.indexOf('ACT_TYPE') > -1
    if (!actType) {
      return true
    }
    const allowAllActType = pathOr(
      true,
      ['companyObj', 'allowAllActType'],
      user
    )
    const hasPermission = includes(mainKey, ['ACT_TYPE1', 'ACT_TYPE2'])

    return allowAllActType ? true : hasPermission
  }, [user, location, urlCompanyCode])

  return {
    hasPagePermission,
  }
}
