import { indexBy, pathOr, prop } from 'ramda'
import { useQuery, UseQueryOptions } from 'react-query'
import { IActDataType, IActivityReliableType } from './options.type'
import React from 'react'

import useComapnyCode from '@/hooks/useComapnyCode'

export const useActDataType = (
  actType?: number,
  options?: UseQueryOptions<any>
) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IActDataType[]>(
    ['/{companyCode}/options/actDataTypes', { companyCode, actType }],
    {
      ...options,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return data ? indexBy(prop('id'), data) : {}
  }, [data])

  return { data, isLoading, refetch, byId }
}

export const useActDataReliableType = (
  actType?: number,
  options?: UseQueryOptions<any>
) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IActivityReliableType[]>(
    ['/{companyCode}/options/actDataReliableTypes', { companyCode, actType }],
    {
      ...options,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return data ? indexBy(prop('id'), data) : {}
  }, [data])

  return { data, isLoading, refetch, byId }
}
