import React from 'react'
import { useQuery, useMutation } from 'react-query'
import { forEach, pathOr, compose } from 'ramda'
import { request } from '@/services'
import message from '@/utils/message'
import { loadFCMToken } from '@/utils/appConnect'
import { isWebView } from '@/utils/browser-detect'
import { useAuth } from './useAuth'
import type { MenuAuth, UserPermission } from './auth.types'
import { clientUrl } from '@/env'
import localStorageUtil from '@lib/utils/localstorage'

const convertData = (auth: MenuAuth) => {
  return {
    id: auth.id,
    name: auth.subName,
    url: auth.webPath,
    icon: auth.icon,
    code: auth.code,
    mainKey: auth.mainKey,
    subKey: auth.subKey,
    isSubMenu: auth.isSub ? auth.isSub : false,
    deletable: auth.authDelete,
    creatable: auth.authAdd,
    updatable: auth.authEdit,
    readable: auth.authView,
    parentId: auth.id,
    parentName: auth.name,
  } as UserPermission
}

const setUserAuth = (auth: MenuAuth, permission: UserPermission) => {
  if (permission) {
    const child = convertData(auth)

    return {
      ...permission,
      children: [...permission.children, child],
    }
  }

  return {
    ...auth,
    url: auth.isSub ? auth.code : auth.webPath, //submenu need unique id
    isSubMenu: auth.isSub ? auth.isSub : false,
    deletable: auth.authDelete,
    creatable: auth.authAdd,
    updatable: auth.authEdit,
    readable: auth.authView,
    ...(auth.isSub
      ? {
          children: [
            { ...convertData(auth), parentId: auth.id, parentName: auth.name },
          ],
        }
      : { children: [] }),
  } as UserPermission
}

export const loginUrlAPI = async () => {
  return request('/nid/loginUrl')
}

export const useLoginUrl = () => {
  const { data, ...others } = useQuery('/nid/loginUrl', loginUrlAPI, {
    cacheTime: Infinity,
  })

  const url: string = React.useMemo(() => {
    return `${pathOr(
      '',
      ['url'],
      data
    )}?client_id=e8dc1ee027084ac5b355b2907ee38d3c&client_url=${clientUrl}`
  }, [data])

  return {
    data,
    url,
    ...others,
  }
}

export const loginAPI = (user: any) => {
  return request('/nid/auth', {
    body: user,
    method: 'POST',
    displayMessage: false,
    cacheTime: Infinity,
  })
}

export const useLogin = (
  onSuccess?: (res: any) => any,
  onError?: (err: any) => any
) => {
  const { setIsAuthenticated, setUser } = useAuth()

  const { mutate, isLoading, ...rest } = useMutation(loginAPI, {
    onError: error => {
      console.log(error)
      onError && onError(error)
    },
    onSuccess: (response: any) => {
      if (!response) {
        return
      }

      if (response?.errorMessage) {
        message({ content: response?.errorMessage, type: 'error' })
        return
      }

      let authById: { [key: string]: UserPermission } = {}
      let authByUrl: { [key: string]: UserPermission } = {}

      compose(
        forEach((x: MenuAuth) => {
          authById[x.name] = setUserAuth(x, authById[x.name])
          authByUrl[x.webPath] = setUserAuth(x, authByUrl[x.webPath])
        }),
        pathOr([], ['authDetails'])
      )(response)

      const funcList = Object.keys(authById).map(i => authById[i])

      localStorageUtil.set('token', response.token)
      localStorageUtil.set('user', { ...response, authByUrl, funcList })

      setIsAuthenticated(true)

      setUser({
        ...response,
        authById,
        authByUrl,
        funcList,
      })

      if (isWebView()) {
        loadFCMToken()
      }

      onSuccess && onSuccess(response)
    },
  })

  return {
    ...rest,
    mutate,
    loading: isLoading,
    login: mutate,
  }
}

export default useLogin
