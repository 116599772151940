import styled from 'styled-components'
import { Input } from 'antd'

const TextArea = styled(Input.TextArea)`
  resize: none;
  border: 1px solid ${p => p.theme.whisper};

  &.ant-input:hover,
  &.ant-input:focus,
  &.ant-input:active {
    border-color: ${p => p.theme.primary};
    box-shadow: none;
  }
`

export default TextArea
